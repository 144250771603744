import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { Button } from 'primereact/button';
import { blue } from '../constants/colors';
import { getUserDivisions } from '../store/usersSlice';

const NotificationData = [
  { divisionName: 'Avanzado - M', link: 'https://t.me/+c7d_mE18ROY2MWI0' },
  { divisionName: 'Inter. Avanzado - M', link: 'https://t.me/+2ipMtrtx5MQ0YTE8' },
  { divisionName: 'Intermedio - M', link: 'https://t.me/+2AXCyLHrAX83NzBk' },
  { divisionName: 'Iniciación - M', link: 'https://t.me/+U0OObaEfbZUyNmQ0' },
  { divisionName: 'Avanzado - F', link: 'https://t.me/+WydTH9qvY9s4NjVk' },
  { divisionName: 'Inter. Avanzado - F', link: 'https://t.me/+LcDT1NM-NWk4MmNk' },
  { divisionName: 'Intermedio - F', link: 'https://t.me/+k64rOznTDMw1Y2M0' },
  { divisionName: 'Iniciación - F', link: 'https://t.me/+gjs3-qD4oZ5hZTc0' },
];

const Notifications: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector((state: RootState) => state.users.userId);
  const userDivisions = useSelector((state: RootState) => state.users.userDivisions);

  useEffect(() => {
    const getUserDivisionsAsync = async () => {
      if (userId) {
        await dispatch(getUserDivisions(userId as string));
      }
    };
    getUserDivisionsAsync();
  }, []);

  const handleButtonPersonalNotificationClick = () => {
    window.open(`https://t.me/PadelMatchLeagueBot?start=${userId}`, '_blank');
  };

  const addDivisionNotificationGroup = (link: string) => {
    window.open(link, '_blank');
  };

  const filteredNotifications = userDivisions !== null ? NotificationData.filter((division) =>
    userDivisions.some(userDivision => userDivision.name === division.divisionName)
  ) : [];

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <p style={{ fontWeight: 'bold', color: '#dc3545', textAlign: 'center', margin: '10px 0' }} className="text-2xl">
        IMPORTANTE: Solo válido desde el celular. <p>Al abrir la conversación, haz click en Start.</p>
      </p>
      <div style={{ paddingBottom: '20px' }}>
        <Button
          label="Recibir actualizaciones personales"
          icon="pi pi-bell"
          onClick={handleButtonPersonalNotificationClick}
          className="p-button-rounded p-button-info"
          style={{ width: '100%', background: blue, borderColor: blue, fontFamily: 'GlacialRegular', height: 60, borderRadius: 16 }}
        />
      </div>
      {filteredNotifications.map((division) => (
        <div key={division.divisionName} style={{ paddingBottom: '20px' }}>
          <Button
            label={`Unirme al grupo de notificaciones de ${division.divisionName}`}
            icon="pi pi-bell"
            onClick={() => addDivisionNotificationGroup(division.link)}
            className="p-button-rounded p-button-warning"
            style={{ width: '100%', fontFamily: 'GlacialRegular', height: 60, borderRadius: 16 }}
          />
        </div>
      ))}
    </div>
  );
};

export default Notifications;
