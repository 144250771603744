import React from 'react';
import { blue, green } from '../../constants/colors';
import { confirmPopup } from 'primereact/confirmpopup';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Badge } from 'primereact/badge';
import { PlayersPairTheme } from './PlayersPairs';


type UserAlreadyJoinedProps = {
    isInCarousel?: boolean;
    theme: PlayersPairTheme;
    canCancel: boolean;
    onUserRemovesFromMatch: () => void;
};

const UserAlreadyJoined: React.FC<UserAlreadyJoinedProps> = ({ theme, isInCarousel, canCancel, onUserRemovesFromMatch }) => {
    const userId = useSelector((state: RootState) => state.users.userId);
    const userImage = userId !== null ? process.env.REACT_APP_STORAGE_URL + userId.toLowerCase() : '';
    const primaryColor = theme === 'blue' ? green : blue;
    const size = isInCarousel ? 40 : 50;

    const onRemoveFromMatchClick = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estas seguro que quieres darte de baja del partido?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => onUserRemovesFromMatch(),
            acceptLabel: 'Si',
            dismissable: true
        });
    };

    return (
        <div className='customCol col cursor-pointer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }} onClick={canCancel ? onRemoveFromMatchClick : undefined}>
            <div style={{ position: 'relative', width: size, height: size }}>
                <img
                    alt={userId !== null ? userId : ''}
                    src={userImage}
                    style={{ borderRadius: '50%', width: '100%', height: '100%', objectFit: 'cover', border: '1px solid', borderColor: primaryColor }}
                />
                {theme === 'blue' && canCancel ? <Badge
                    severity="danger"
                    value={<div className="pi pi-times font-bold" style={{ fontSize: 10 }} />}
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '-5px',
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 0
                    }}
                    size={null}
                >
                </Badge> : null}
            </div>
            <div className='text-sm font-bold' style={{ color: primaryColor }}>Anotado</div>
        </div>
    );
};

export default UserAlreadyJoined;
