import { AppDispatch } from '../../store';
import apiClient from '../apiClient';
import { User } from '../apiTypes';

export const fetchUser = async (userId: string): Promise<User> => {
  const response = await apiClient.get<User>(`/users/${userId}`);
  return response.data;
};

export const login = (email: string, password: string) => async (dispatch: AppDispatch): Promise<{ token: string, userId: string, roles: string[], nickname: string }> => {
  const response = await apiClient.post('/users/login', { email, password });
  return response.data;
};

export const requestPasswordReset = async (email: string): Promise<{ message: string }> => {
  const response = await apiClient.post('/users/forgot-password', { email });
  return response.data;
};

export const resetPassword = async (email: string, token: string, newPassword: string): Promise<{ message: string }> => {
  const response = await apiClient.post('/users/reset-password', { email, token, newPassword });
  return response.data;
};
