import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setFormData } from '../store/registrationSlice';
import FormInput from './FormInput';
import './styles';
import './styles/RegistrationForm.css';
import CountryDropdown from './CountryDropdown';
import 'react-phone-number-input/style.css';
import { Country } from 'react-phone-number-input';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';
import { Departments, GenderLabels } from '../api/apiTypes';

interface BirthCountry {
  name: string;
  code: string;
}

const countries: BirthCountry[] = [
  { name: 'Uruguay', code: 'UY' },
  { name: 'Alemania', code: 'DE' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Brasil', code: 'BR' },
  { name: 'Chile', code: 'CL' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'España', code: 'ES' },
  { name: 'Estados Unidos', code: 'US' },
  { name: 'Francia', code: 'FR' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Perú', code: 'PE' },
  { name: 'Venezuela', code: 'VE' },
];

const NICKNAME_DISCLAIMER = 'Tu apodo será tu identificador. Asegúrate de que sea único y representativo.';

const RegistrationForm = forwardRef<any, { onSubmit: (data: any) => void }>((props, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const formData = useSelector((state: RootState) => state.registration);
  const methods = useForm({ defaultValues: formData });
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    trigger,
    getValues
  } = methods;
  const [country, setCountry] = useState<Country | undefined>('UY');

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(props.onSubmit)(),
    clear: () => reset(),
    trigger: () => trigger,
  }));

  const handleChange = () => {
    dispatch(setFormData(methods.getValues()));
  };

  const handleCountryChange = (newCountry: Country | undefined) => {
    setCountry(newCountry);
  };

  const profilePictureName = getValues('profilePicture')?.name

  const pictureName = profilePictureName !== undefined ? `Imagen elegida: ${profilePictureName}` : undefined;

  const password = watch('password');
  const confirm = watch('confirmPassword');
  useEffect(() => {
    if (password && confirm) {
      trigger('confirmPassword');
    }
  }, [password, confirm, trigger]);

  return (
    <FormProvider {...methods}>
      <form onChange={handleChange} className="p-fluid">
        <FormInput name="name" control={control} label="Nombre" placeholder="Agustin" valueType="text" defaultValue="" rules={{ required: 'El nombre es obligatorio.' }} errors={errors} hasFloatLabel={false} />
        <FormInput name="lastName" control={control} label="Apellido" placeholder="Tapia" valueType="text" defaultValue="" rules={{ required: 'El apellido es obligatorio.' }} errors={errors} hasFloatLabel={false} />
        <FormInput
          name="gender"
          hasFloatLabel={false}
          control={control}
          label="Género"
          placeholder="Hombre/Mujer"
          type="dropdown"
          options={GenderLabels}
          rules={{ required: 'El género es obligatorio.' }}
          errors={errors}
        />
        <FormInput
          name="nickname"
          control={control}
          label="Apodo"
          placeholder="Mozart"
          defaultValue=""
          rules={{ required: 'El apodo es obligatorio.', maxLength: { value: 12, message: 'Tu apodo no debe superar los 12 caracteres.' } }}
          errors={errors}
          hasFloatLabel={false}
          helpText={NICKNAME_DISCLAIMER}
        />
        <FormInput
          name="phoneNumber"
          control={control}
          label="Número de Teléfono"
          hasFloatLabel={false}
          type="phone"
          defaultCountry="UY"
          countries={countries.map(c => c.code) as CountryCode[]}
          onCountryChange={handleCountryChange}
          placeholder="Ingresa tu número"
          rules={{
            required: 'El número de teléfono es obligatorio.',
            validate: (value: string) => {
              const parsedPhoneNumber = parsePhoneNumberFromString(value, country);
              return parsedPhoneNumber?.isValid() || 'Número de teléfono inválido.';
            },
          }}
          errors={errors}
        />
        <FormInput
          name="birthDate"
          hasFloatLabel={false}
          control={control}
          label="Fecha de nacimiento"
          placeholder="05/12/1996"
          type="date"
          defaultValue={null}
          rules={{ required: 'La fecha de nacimiento es obligatoria.' }}
          errors={errors}
        />
        <div className="p-field">
          <label htmlFor="birthCountry">Lugar de nacimiento</label>
          <Controller
            name="birthCountry"
            control={control}
            defaultValue={undefined}
            rules={{ required: 'El lugar de nacimiento es obligatorio.' }}
            render={({ field }) => <CountryDropdown value={field.value} onChange={value => field.onChange(value)} countries={countries} placeholder="Lugar de nacimiento" errors={errors.birthCountry} />}
          />
          {errors.birthCountry && <small className="p-error">{errors.birthCountry.message}</small>}
        </div>
        <FormInput
          name="department"
          hasFloatLabel={false}
          control={control}
          label="Departamento en el que vivís"
          placeholder="Departamento"
          type="dropdown"
          options={Departments}
          rules={{ required: 'El departamento es obligatorio.' }}
          errors={errors}
        />
        <FormInput
          name="email"
          hasFloatLabel={false}
          control={control}
          label="Email"
          placeholder="Agus.tapia@gmail.com"
          defaultValue=""
          rules={{
            required: 'El email es obligatorio.',
            pattern: {
              value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message: 'Email inválido.',
            },
          }}
          errors={errors}
        />
        <FormInput
          name="password"
          hasFloatLabel={false}
          control={control}
          label="Contraseña"
          placeholder="Contraseña"
          type="password"
          defaultValue=""
          rules={{
            required: 'La confirmación de contraseña es obligatoria.',
            minLength: { value: 8, message: 'La contraseña debe tener al menos 8 caracteres.' },
          }}
          helpText={'La contraseña debe tener al menos 8 caracteres.'}
          minLength={8}
          errors={errors}
          toggleMask={true}
        />
        <FormInput
          name="confirmPassword"
          hasFloatLabel={false}
          control={control}
          label="Confirmar Contraseña"
          placeholder="Confirmar Contraseña"
          type="password"
          defaultValue=""
          rules={{
            required: 'La confirmación de contraseña es obligatoria.',
            validate: (value: string) => value === password || 'Las contraseñas no coinciden.',
            minLength: { value: 8, message: 'La contraseña debe tener al menos 8 caracteres.' },
          }}
          helpText={'La contraseña debe tener al menos 8 caracteres.'}
          errors={errors}
          toggleMask={true}
        />
        <FormInput name="profilePicture" helpText={pictureName} required hasFloatLabel={false} control={control} rules={{ required: 'La imagen de perfil es obligatoria.' }} label="Elegir imagen de perfil" type="file" errors={errors} />
      </form>
    </FormProvider>
  );
});

export default RegistrationForm;
