import { AxiosError } from "axios";

export const getErrorMessage = (error: unknown) => {
    let errorMessage = 'Ocurrió un error inesperado.';

    if (error instanceof AxiosError) {
        if (error.response) {
            // If the response data is an array, use the first item's description.
            if (Array.isArray(error.response.data) && error.response.data[0]) {
                errorMessage = error.response.data[0].description || 'Ocurrió un error al procesar tu solicitud.';
            } else if (typeof error.response.data === 'string') {
                // If the response data is a string, use it directly.
                errorMessage = error.response.data;
            } else {
                // If it's an object, check if it has a message or description property.
                errorMessage = error.response.data.description || 'Ocurrió un error al procesar tu solicitud.';
            }
        } else if (error.request) {
            // The request was made but no response was received
            errorMessage = 'No se pudo establecer conexión con el servidor. Verifica tu conexión a internet.';
        } else {
            // Something happened in setting up the request that triggered an error
            errorMessage = error.message;
        }
    }
    return errorMessage.toString();
}