import React from 'react';
import { grey } from '../../constants/colors';
import { PlayersPairTheme } from './PlayersPairs';


type CompleteSpotProps = {
    theme: PlayersPairTheme;
    isInCarousel?: boolean;
};

const CompleteSpot: React.FC<CompleteSpotProps> = ({ theme, isInCarousel }) => {
    const fontColor = theme === 'blue' ? '#FFFFFF' : undefined
    const size = isInCarousel ? 40 : 50;

    return (
        <div className='customCol col p-0' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ height: size, width: size, borderRadius: '50%', backgroundColor: grey, display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                <i className="pi pi-user text-xl font-bold" />
            </div>
            <div className='text-sm' style={{ color: fontColor }}>Completo</div>
        </div>
    );
};

export default CompleteSpot;
