import React from 'react';
import { MatchResponseDto } from '../api/apiTypes';
import './styles/MatchMainInfo.css';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { divisionName } from '../store/configSlice';
import { Skeleton } from 'primereact/skeleton';

type MatchMainInfoProps = {
  isLoading: boolean;
  match: MatchResponseDto | null;
};

const MatchMainInfo: React.FC<MatchMainInfoProps> = ({ isLoading, match }) => {
  const divisionLabel = useSelector((state: RootState) =>
    match ? divisionName(match.divisionId)(state) : ''
  );

  const formattedDate = match ? moment.utc(match.date).local().format('dddd DD-MMM') : '';
  const formattedTime = match ? moment.utc(match.date).local().format('HH:mm') : '';
  const formattedCourtPrice = match
    ? new Intl.NumberFormat('es-UY', { style: 'decimal', minimumFractionDigits: 0 }).format(Number(match.courtPrice))
    : '';

  const LoadingComponent = (
    <div className="match-main-info-container" style={{ width: '100%' }}>
      <Skeleton width="50%" height="2rem" className="mb-2"></Skeleton>
      <div className='info-container'>
        <div className="mb-2">
          <Skeleton width="100%" height="1.5rem" className="mb-1"></Skeleton>
          <Skeleton width="80%" height="1.5rem" className="mb-1"></Skeleton>
        </div>
        <div className="mb-2">
          <Skeleton width="100%" height="1.5rem" className="mb-1"></Skeleton>
          <Skeleton width="60%" height="1rem" className="mb-1"></Skeleton>
        </div>
        <div className="custom-divider mb-2 opacity-70"></div>
        <div className='grid'>
          <div className='col'>
            <Skeleton width="100%" height="1rem" className="mb-1"></Skeleton>
            <Skeleton width="50%" height="1.5rem" className="mx-auto"></Skeleton>
          </div>
          <div className='col'>
            <Skeleton width="100%" height="1rem" className="mb-1"></Skeleton>
            <Skeleton width="50%" height="1.5rem" className="mx-auto"></Skeleton>
          </div>
        </div>
      </div>
    </div>
  );

  if (isLoading || !match) {
    return LoadingComponent;
  }

  return (
    <div className="match-main-info-container">
      <span className='title text-2xl'>{divisionLabel}</span>
      <div className='info-container'>
        <div style={{ flexDirection: 'row' }}>
          <i className="pi pi-calendar pr-2 text-xl font-bold" />
          <span className='font-bold text-xl capitalize'>{`${formattedDate} | ${formattedTime}`}</span>
        </div>
        <div style={{ flexDirection: 'row', paddingTop: 12 }}>
          <i className="pi pi-map-marker pr-2 text-xl font-bold" />
          <span className='font-bold text-xl capitalize'>{match.clubName}</span>
        </div>
        <span className='text-l capitalize'>{match.clubAddress}</span>
        <div className="custom-divider pt-2"></div>
        <div className='grid'>
          <div className='col'>
            <div className='text-xl text-center line-height-1'>{'Precio total cancha'}</div>
            <div className='text-xl text-center font-bold'>{`$ ${formattedCourtPrice}`}</div>
          </div>
          <div className='col'>
            <div>
              <div className='text-xl text-center line-height-1'>{'Precio por jugador'}</div>
            </div>
            <div className='text-xl text-center font-bold'>{`$ ${parseInt(match.courtPrice as string) / 4}`}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchMainInfo;