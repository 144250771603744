import React from 'react';
import { MatchResponseDto } from '../api/apiTypes';
import './styles/MatchExtraInfo.css';
import { Skeleton } from 'primereact/skeleton';

type MatchExtraInfoProps = {
  isLoading: boolean;
  match: MatchResponseDto;
};

const MatchExtraInfo: React.FC<MatchExtraInfoProps> = ({ isLoading, match }) => {

  const LoadingComponent = (
    <div className="extra-info-container ">
      <Skeleton width="60%" height="1.5rem" className="mb-3" />
      <div className='pl-2 px-2'>
        <Skeleton width="60%" height="1.2rem" className="mb-1" />
        <Skeleton width="80%" height="0.8rem" className="mb-3" />
        <Skeleton width="60%" height="1.2rem" className="mb-1" />
        <Skeleton width="80%" height="0.8rem" className="mb-1" />
      </div>
    </div>
  )

  return (
    isLoading ? LoadingComponent :
      (<div className="extra-info-container ">
        <span className='extra-info-title text-xl'>Información extra</span>
        <div className='pl-2'>
          <div className='text-xl capitalize'>Cancha:</div>
          <span className='text-l pl-1 font-bold capitalize'>{match.courtName}</span>
          <div className='text-xl capitalize pt-2'>Creador del partido:</div>
          <span className='text-l pl-1 font-bold capitalize'>{match.creatorNickName}</span>
        </div>
      </div>)
  );
};

export default MatchExtraInfo;
