import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFinishedMatchesBySeasonAndDivision } from '../store/matchesSlice';
import { AppDispatch, RootState } from '../store';
import './styles/Results.css';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { DivisionResponseDto, SeasonResponseDto } from '../api/apiTypes';
import MatchFinishedCard from '../components/MatchFinishedCard';
import { blue } from '../constants/colors';

const Results: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const matches = useSelector((state: RootState) => state.matches.finishedMatches);
  const loading = useSelector((state: RootState) => state.matches.loading);
  const config = useSelector((state: RootState) => state.configuration);
  const userDivisions = useSelector((state: RootState) => state.users.userDivisions);

  const userDivision = userDivisions && userDivisions.length > 0 ? userDivisions[0] : undefined;
  const firstSeason = config.seasons && config.seasons.length > 0 ? config.seasons[0] : undefined;

  const [selectedDivision, setSelectedDivision] = useState<DivisionResponseDto | undefined>(userDivision);
  const [selectedSeason, setSelectedSeason] = useState<SeasonResponseDto | undefined>(firstSeason);

  useEffect(() => {
    if (userDivision && !selectedDivision) {
      setSelectedDivision(userDivision);
    }
  }, [userDivision, selectedDivision]);

  useEffect(() => {
    const getFinishedMatches = async () => {
      await dispatch(fetchFinishedMatchesBySeasonAndDivision(selectedSeason?.id as number, selectedDivision?.id as number));
    };
    getFinishedMatches();
  }, [selectedSeason, selectedDivision, dispatch]);

  return (
    <div className="feed-container">
      <div style={{ maxWidth: 1200, flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 12 }}>
          <div style={{ backgroundColor: blue, padding: 10, borderRadius: 10, marginBottom: 6, flex: 1 }}>
            <div className="text-2xl font-bold pb-3" style={{ color: '#BDE83F', fontFamily: 'TTRunsBold' }}>
              Resultados
            </div>
            <div>
              <div className="grid grid-nogutter" style={{ borderRadius: 8, paddingInline: 6, marginBlock: 8 }}>
                <div className="col-4 flex align-items-center justify-content-start">
                  <span className="text-xl" style={{ color: '#BDE83F' }}>
                    Temporada
                  </span>
                </div>
                <div className="col">
                  <Dropdown
                    value={selectedSeason}
                    onChange={(e: DropdownChangeEvent) => setSelectedSeason(e.value)}
                    options={[...(config.seasons as [])]}
                    optionLabel="name"
                    placeholder="Temporada"
                    className="w-full md:w-14rem my-2 filters-dropdown"
                    checkmark={true}
                    highlightOnSelect={false}
                  />
                </div>
              </div>
              <div className="grid grid-nogutter" style={{ borderRadius: 8, paddingInline: 6, marginBlock: 8 }}>
                <div className="col-4 flex align-items-center justify-content-start">
                  <span className="text-xl" style={{ color: '#BDE83F' }}>
                    Categoría
                  </span>
                </div>
                <div className="col">
                  <Dropdown
                    value={selectedDivision}
                    onChange={(e: DropdownChangeEvent) => setSelectedDivision(e.value)}
                    options={[...(config.divisions as [])]}
                    optionLabel="name"
                    placeholder="Categoría"
                    className="w-full md:w-14rem my-2 filters-dropdown"
                    checkmark={true}
                    highlightOnSelect={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="body-results-container">
          {matches && matches.length > 0 ? (
            matches.map((match, index) => (
              <div key={index} className="result-container">
                <MatchFinishedCard match={match} isLoading={loading} />
              </div>
            ))
          ) : (
            <h2>No hay resultados disponibles para la búsqueda</h2>
          )}
        </div>
      </div>
    </div>

  );
};

export default Results;
