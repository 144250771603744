import React from 'react';
import './styles/LoadingModal.css';
import { ProgressSpinner } from 'primereact/progressspinner';

const LoadingModal: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    if (!isLoading) return null;

    return (
        <div className="modal-overlay">
            <ProgressSpinner />
        </div>
    );
};

export default LoadingModal;
