import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { logout } from '../store/usersSlice';
import { Toast } from 'primereact/toast';
import { Menu } from 'primereact/menu';
import { MenuItem } from 'primereact/menuitem';

const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userId = useSelector((state: RootState) => state.users.userId);
  const isAuthenticated = useSelector((state: RootState) => state.users.isAuthenticated);
  const userMenuToastRef = useRef<Toast>(null);
  const userMenuRef = useRef<Menu>(null);
  const isMobile = window.innerWidth < 768;
  const userImage = userId !== null ? process.env.REACT_APP_STORAGE_URL + userId.toLowerCase() : '';

  const isAuthorizedForRole = (neededRole: string[]) => {
    const storedRoles = localStorage.getItem('roles');
    if (!storedRoles) {
      return false
    }
    const roles = storedRoles ? JSON.parse(storedRoles) as string[] : [];
    if (!roles) {
      return false
    }
    return !roles ? false : roles.some((role) => neededRole.includes(role))
  }

  const handleLogOut = () => {
    dispatch(logout());
    userMenuToastRef.current?.show({ severity: 'success', summary: 'Logged out', detail: 'You have been logged out successfully' });
  };

  const userMenuItems: MenuItem[] = [
    {
      label: 'Opciones',
      items: [
        ...(!isMobile
          ? [
            {
              label: 'Mi Perfil',
              icon: 'pi pi-user',
              command: () => navigate(`/user-information/${userId}`),
            }
          ]
          : []),
        {
          label: 'Notificaciones',
          icon: 'pi pi-fw pi-bell',
          command: () => navigate('/notifications'),
        },
        {
          label: 'Reglamento',
          icon: 'pi pi-fw pi-book',
          command: () => navigate('/regulation'),
        },
        ...(isAuthorizedForRole(['Admin'])
          ? [
            {
              label: 'Admin',
              icon: 'pi pi-pen-to-square',
              command: () => navigate('/admin'),
            },
          ]
          : []),
        {
          label: 'Cerrar sesión',
          icon: 'pi pi-sign-out',
          command: handleLogOut,
        },
      ],
    },
  ];

  return isAuthenticated ? (
    <>
      <img
        alt={userId !== null ? userId : ''}
        src={userImage}
        style={{ borderRadius: '50%', width: '38px', height: '38px', objectFit: 'cover' }}
        onClick={(event) => userMenuRef.current?.toggle(event)}
      />
      <Toast ref={userMenuToastRef} />
      <Menu model={userMenuItems} popup ref={userMenuRef} id="popup_menu_left" />
    </>
  ) : null;
};

export default UserMenu;
