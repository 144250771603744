import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { CancelMatchDto, JoinMatchDto, MatchResponseDto } from '../api/apiTypes';
import { createMatch, updateMatchForUser, getDivisionPendingMatches, getUserAvailableMatches, joinMatch as joinMatchService, getMatchDetail, cancelMatch as cancelMatchService, getFinishedMatchesBySeasonAndDivision, removeFromMatchService } from '../api/matches/matchesApi';
import { AppDispatch } from '.';
import { setLoading, showNotification } from './notificationSlice';
import { MatchData } from '../pages/AddMatch';
import { getErrorMessage } from '../helpers/manageErrors';

interface MatchesState {
  userAvailableMatches: MatchResponseDto[];
  finishedMatches: MatchResponseDto[];
  loading: boolean;
  error: string | null;
}

const initialState: MatchesState = {
  userAvailableMatches: [],
  finishedMatches: [],
  loading: false,
  error: null,
};

const matchesSlice = createSlice({
  name: 'matches',
  initialState,
  reducers: {
    createMatchSuccess: (state) => {

    },
    createMatchFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    getUserAvailableMatchesSuccess: (state, action: PayloadAction<MatchResponseDto[]>) => {
      state.userAvailableMatches = action.payload;
    },
    getDivisionPendingMatchesSuccess: (state, action: PayloadAction<MatchResponseDto[]>) => {
      state.userAvailableMatches = action.payload;
    },
    getFinishedMatchesBySeasonAndDivisionSuccess: (state, action: PayloadAction<MatchResponseDto[]>) => {
      state.finishedMatches = action.payload;
    },
  },
});

export const { createMatchSuccess, createMatchFailure, getUserAvailableMatchesSuccess, getDivisionPendingMatchesSuccess, getFinishedMatchesBySeasonAndDivisionSuccess } = matchesSlice.actions;


export const fetchUserAvailableMatches = (userId: string, divisionId?: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getUserAvailableMatches(userId, divisionId);
    dispatch(getUserAvailableMatchesSuccess(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchFinishedMatchesBySeasonAndDivision = (seasonId: number, divisionId: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getFinishedMatchesBySeasonAndDivision(seasonId, divisionId);
    dispatch(getFinishedMatchesBySeasonAndDivisionSuccess(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export const fetchMatchDetails = (matchId: string, userId: string) => async (dispatch: AppDispatch) => {
  try {
    const response = await getMatchDetail(matchId, userId);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const addMatch = (match: MatchData) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true))
    await createMatch(match);
    dispatch(showNotification({ severity: 'success', summary: 'Partido creado con exito.' }));
  } catch (error) {
    dispatch(createMatchFailure('Correo electrónico o contraseña inválidos'));
    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al crear el partido.', detail: getErrorMessage(error) }));
  }
  finally {
    dispatch(setLoading(false))
  }
};

export const cancelMatch = (cancelMatchDto: CancelMatchDto) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true))
    await cancelMatchService(cancelMatchDto);
    dispatch(showNotification({ severity: 'success', summary: 'Partido cancelado con exito.' }));
  } catch (error) {
    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al cancelar el partido.', detail: getErrorMessage(error) }));
  } finally {
    dispatch(setLoading(false))
  }
};

export const joinMatch = (joinMatchDto: JoinMatchDto) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true))
    await joinMatchService(joinMatchDto)
    dispatch(showNotification({ severity: 'success', summary: 'Te has unido al partido con exito.' }));
  } catch (error) {
    console.log(error)
    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al unirte el partido.', detail: getErrorMessage(error) }));
  } finally {
    dispatch(setLoading(false))
  }
}

export const removeFromMatch = (matchId: string, userId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true))
    await removeFromMatchService(matchId, userId)
    dispatch(showNotification({ severity: 'success', summary: 'Te has dado de baja del partido con exito.' }));
  } catch (error) {
    console.log(error)
    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al darte de baja del partido.', detail: getErrorMessage(error) }));
  } finally {
    dispatch(setLoading(false))
  }
}

export const updateMatchUser = createAsyncThunk<void, { matchId: string, userId: string }>(
  'matches/updateMatchUser',
  async ({ matchId, userId }) => {
    await updateMatchForUser(matchId, userId);
  }
);

export default matchesSlice.reducer;
