import { useDispatch } from 'react-redux';
import { setRegisterModalVisibility } from '../store/notificationSlice';
import './styles/RegisterModal.css'
import { AppDispatch } from '../store';

export default function RegisterModal({ visible }: { visible: boolean }) {
    const dispatch = useDispatch<AppDispatch>();
    if (!visible) return null;


    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    <i className={`pi pi-check-circle-icon`}></i>
                    <h2 className="modal-title">🎾 Listo, ya estás registrado 🎾</h2>
                    <p className="modal-text">
                        Te enviamos un correo para activar tu cuenta.
                        <p>
                            Revisa tu bandeja de entrada o la <b>carpeta de spam.
                            </b>
                        </p>
                        <p>
                            ¡Nos vemos en la cancha!
                        </p>
                    </p>
                    <button className="close-button" onClick={() => dispatch(setRegisterModalVisibility(false))}>
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    )
}