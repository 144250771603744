import './styles';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useCallback } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { fetchActiveUsers, fetchAddUsersToDivisions, fetchAddUsersToSeasons, fetchUsers } from '../store/usersSlice';
import { AppDispatch, RootState } from '../store';
import { DivisionResponseDto, SeasonResponseDto, UserDivisionDto, UserSeasonDivisionDto, UserSeasonDto } from '../api/apiTypes';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Image } from 'primereact/image'

const Admin: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const config = useSelector((state: RootState) => state.configuration);
    const loading = useSelector((state: RootState) => state.users.loading);
    const [users, setUsers] = useState<UserSeasonDivisionDto[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<UserSeasonDivisionDto[] | null>(null);
    const [selectedSeason, setSelectedSeason] = useState<SeasonResponseDto | undefined>(undefined);
    const [selectedDivision, setSelectedDivision] = useState<DivisionResponseDto | undefined>(undefined);
    const [visibleSeasonModal, setVisibleSeasonModal] = useState(false);
    const [visibleDivisionModal, setVisibleDivisionModal] = useState(false);
    const [visibleActiveUsersModal, setVisibleActiveUsersModal] = useState(false);
    const [errorSeason, setErrorSeason] = useState<string | null>(null);
    const [errorDivision, setErrorDivision] = useState<string | null>(null);
    const [errorActiveUsers, setErrorActiveUsers] = useState<string | null>(null);

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const getUsersAsync = useCallback(async () => {
        try {
            const users = await dispatch(fetchUsers());
            if (users) setUsers(users as any);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    }, [dispatch]);

    useEffect(() => {
        getUsersAsync();
    }, [getUsersAsync]);

    const onCloseSeasonModal = () => {
        setVisibleSeasonModal(false);
        setErrorSeason(null); // Clear error on close
        setSelectedSeason(undefined); // Clear selected season
    };

    const onCloseActiveUsersModal = () => {
        setVisibleActiveUsersModal(false);
        setErrorActiveUsers(null); // Clear error on close
    };

    const onCloseDivisionModal = () => {
        setVisibleDivisionModal(false);
        setErrorDivision(null); // Clear error on close
        setSelectedDivision(undefined); // Clear selected division
    };

    const handleConfirmSeasonModal = async () => {
        if (selectedSeason && selectedUsers) {
            const usersSeasons: UserSeasonDto[] = selectedUsers.map(user => ({
                userId: user.id,
                seasonId: selectedSeason.id
            }));
            try {
                await dispatch(fetchAddUsersToSeasons(usersSeasons));
                setSelectedSeason(undefined); // Clear selected season
                setSelectedUsers(null); // Clear selected users        
                await getUsersAsync(); // Refresh table data
                onCloseSeasonModal(); // Close modal and reset states on success
            } catch (error) {
                setErrorSeason('Failed to add users to the selected season. Please try again.');
                console.error('Error adding users to seasons:', error);
            }
        } else {
            setErrorSeason('Please select a season and ensure users are selected.');
        }
    };

    const handleConfirmActiveUsersModal = async () => {
        if (selectedUsers) {
            const userIds = selectedUsers.map(user => user.id);
            try {
                await dispatch(fetchActiveUsers(userIds));
                setSelectedUsers(null); // Clear selected users        
                await getUsersAsync(); // Refresh table data
                onCloseActiveUsersModal(); // Close modal and reset states on success
            } catch (error) {
                setErrorActiveUsers('Failed to add users to the selected division. Please try again.');
                console.error('Error adding users to divisions:', error);
            }
        } else {
            setErrorActiveUsers('Please select a division and ensure users are selected.');
        }
    };

    const handleConfirmDivisionModal = async () => {
        if (selectedDivision && selectedUsers) {
            const usersDivisions: UserDivisionDto[] = selectedUsers.map(user => ({
                userId: user.id,
                divisionId: selectedDivision.id
            }));
            try {
                await dispatch(fetchAddUsersToDivisions(usersDivisions));
                setSelectedUsers(null); // Clear selected users        
                await getUsersAsync(); // Refresh table data
                onCloseDivisionModal(); // Close modal and reset states on success
            } catch (error) {
                setErrorDivision('Failed to add users to the selected division. Please try again.');
                console.error('Error adding users to divisions:', error);
            }
        } else {
            setErrorDivision('Please select a division and ensure users are selected.');
        }
    };

    const representativeNicknameBodyTemplate = (rowData: UserSeasonDivisionDto) => {
        return (
            <div className="flex align-items-center gap-2">
                <Image alt={rowData.nickname as string} src={process.env.REACT_APP_STORAGE_URL + rowData.id.toLowerCase()} width="32" imageStyle={{ borderRadius: '50%', width: '32px', height: '32px', objectFit: 'cover' }} preview />
                <span className="font-bold">{rowData.nickname}</span>
            </div>
        );
    };

    const representativeSeasonBodyTemplate = (rowData: UserSeasonDivisionDto) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold">{rowData.seasons
                    ?.map(season => season.name)
                    .join(' - ') || ''}</span>
            </div>
        );
    };

    const representativeDivisionBodyTemplate = (rowData: UserSeasonDivisionDto) => {
        return (
            <div className="flex align-items-center gap-2">
                <span className="font-bold">{rowData.divisions
                    ?.map(division => division.name)
                    .join(' - ') || ''}</span>
            </div>
        );
    };

    const representativeActiveBodyTemplate = (rowData: UserSeasonDivisionDto) => {
        return (
            <div className="flex align-items-center gap-2">
                <Checkbox checked={rowData.isActive} disabled />
            </div>
        );
    };

    const onGlobalFilterChange = (e: any) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Buscar por apodo' />
                </IconField>
            </div>
        );
    };

    const header = renderHeader();

    const handleSeasonBtnClick = () => {
        setVisibleSeasonModal(true);
    };

    const handleDivisionBtnClick = () => {
        setVisibleDivisionModal(true);
    };

    const handleActiveUsersBtnClick = () => {
        setVisibleActiveUsersModal(true);
    };

    return (
        <>
            <h1>Administrador</h1>
            <div className="flex flex-col md:flex-row gap-4 p-4">
                <Button
                    label="Agregar usuarios a temporada"
                    rounded
                    onClick={handleSeasonBtnClick}
                    disabled={!selectedUsers || selectedUsers.length === 0}
                />
                <Button
                    label="Agregar usuarios a una categoría"
                    rounded
                    onClick={handleDivisionBtnClick}
                    disabled={!selectedUsers || selectedUsers.length === 0}
                />
                <Button
                    label="Activar usuarios"
                    rounded
                    onClick={handleActiveUsersBtnClick}
                    disabled={!selectedUsers || selectedUsers.length === 0}
                />
            </div>
            <div>
                <DataTable
                    value={users}
                    selection={selectedUsers}
                    onSelectionChange={e => setSelectedUsers(e.value as any)}
                    dataKey="id"
                    header={header}
                    filters={filters}
                    globalFilterFields={['nickname']}
                    emptyMessage="No existe ningún usuario."
                    loading={loading}
                    rowGroupMode="rowspan"
                    sortMode="single"
                    stripedRows
                    sortOrder={1}
                    tableStyle={{ minWidth: '50rem' }}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="nickname" header="Nombre" body={representativeNicknameBodyTemplate}></Column>
                    <Column field="season" header="Temporadas" body={representativeSeasonBodyTemplate}></Column>
                    <Column field="divisions" header="Categorías" body={representativeDivisionBodyTemplate}></Column>
                    <Column field="divisions" header="Activo" body={representativeActiveBodyTemplate}></Column>
                </DataTable>
            </div>
            <Dialog
                header="Seleccionar temporada"
                visible={visibleSeasonModal}
                onHide={onCloseSeasonModal}
                style={{ width: '90vw', maxWidth: '600px', borderRadius: '15px' }}
                modal
                className="p-fluid p-dialog-custom"
            >
                <div className="p-fluid">
                    <p className="text-center mb-4 font-semibold text-lg text-primary">
                        Usuarios seleccionados: {selectedUsers?.map(user => user.nickname).join(' - ') || ''}
                    </p>
                    <div className="field mb-4">
                        <label htmlFor="season" className="block text-sm font-medium mb-2 text-secondary">Seleccione la temporada a la que los quiere agregar</label>
                        <Dropdown
                            value={selectedSeason}
                            onChange={(e: DropdownChangeEvent) => setSelectedSeason(e.value)}
                            options={config.seasons}
                            optionLabel="name"
                            placeholder="Temporada"
                            className="w-full rounded-lg border-2 border-primary p-dropdown-custom"
                            checkmark
                            highlightOnSelect={false}
                        />
                    </div>
                    {errorSeason && <p className="text-red-600 mb-4">{errorSeason}</p>}
                    <div className="flex justify-content-end gap-3">
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={onCloseSeasonModal}
                            className="p-button-secondary p-button-rounded"
                        />
                        <Button
                            label="Confirmar"
                            icon="pi pi-check"
                            onClick={handleConfirmSeasonModal}
                            className="p-button-primary p-button-rounded"
                            disabled={!selectedSeason}
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Confirmar usuarios a activar"
                visible={visibleActiveUsersModal}
                onHide={onCloseActiveUsersModal}
                style={{ width: '90vw', maxWidth: '600px', borderRadius: '15px' }}
                modal
                className="p-fluid p-dialog-custom"
            >
                <div className="p-fluid">
                    <p className="text-center mb-4 font-semibold text-lg text-primary">
                        Usuarios seleccionados: {selectedUsers?.map(user => user.nickname).join(' - ') || ''}
                    </p>
                    {errorActiveUsers && <p className="text-red-600 mb-4">{errorActiveUsers}</p>}
                    <div className="flex justify-content-end gap-3">
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={onCloseActiveUsersModal}
                            className="p-button-secondary p-button-rounded"
                        />
                        <Button
                            label="Confirmar"
                            icon="pi pi-check"
                            onClick={handleConfirmActiveUsersModal}
                            className="p-button-primary p-button-rounded"
                        />
                    </div>
                </div>
            </Dialog>
            <Dialog
                header="Seleccionar una categoría"
                visible={visibleDivisionModal}
                onHide={onCloseDivisionModal}
                style={{ width: '90vw', maxWidth: '600px', borderRadius: '15px' }}
                modal
                className="p-fluid p-dialog-custom"
            >
                <div className="p-fluid">
                    <p className="text-center mb-4 font-semibold text-lg text-primary">
                        Usuarios seleccionados: {selectedUsers?.map(user => user.nickname).join(' - ') || ''}
                    </p>
                    <div className="field mb-4">
                        <label htmlFor="division" className="block text-sm font-medium mb-2 text-secondary">Seleccione la categoría a la que los quiere agregar</label>
                        <Dropdown
                            value={selectedDivision}
                            onChange={(e: DropdownChangeEvent) => setSelectedDivision(e.value)}
                            options={config.divisions}
                            optionLabel="name"
                            placeholder="Categoría"
                            className="w-full rounded-lg border-2 border-primary p-dropdown-custom"
                            checkmark
                            highlightOnSelect={false}
                        />
                    </div>
                    {errorDivision && <p className="text-red-600 mb-4">{errorDivision}</p>}
                    <div className="flex justify-content-end gap-3">
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            onClick={onCloseDivisionModal}
                            className="p-button-secondary p-button-rounded"
                        />
                        <Button
                            label="Confirmar"
                            icon="pi pi-check"
                            onClick={handleConfirmDivisionModal}
                            className="p-button-primary p-button-rounded"
                            disabled={!selectedDivision}
                        />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default Admin;
