import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import MatchCard from '../components/MatchCard';
import MatchFinishedCard from '../components/MatchFinishedCard';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { MatchResponseDto } from '../api/apiTypes';
import { Skeleton } from 'primereact/skeleton';
import 'primereact/resources/themes/saga-blue/theme.css'; // Choose your theme
import 'primereact/resources/primereact.min.css';
import './styles/Dashboard.css';
import { getFeed } from '../store/usersSlice';
import { Link, useNavigate } from 'react-router-dom';
import { blue, green } from '../constants/colors';
import FAQ from '../components/FAQ';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userId = useSelector((state: RootState) => state.users.userId);
  const nickname = useSelector((state: RootState) => state.users.nickname);
  const feedInformation = useSelector((state: RootState) => state.users.feedInformation);
  const isLoading = useSelector((state: RootState) => state.users.loading);
  const isMobile = window.innerWidth < 768;

  const getFeedAsync = async () => {
    await dispatch(getFeed(userId as string));
  };

  useEffect(() => {
    getFeedAsync();
  }, [userId, dispatch]);

  const responsiveOptions = [
    {
      breakpoint: '1700px',
      numVisible: 3,
      numScroll: 1,
    },
    {
      breakpoint: '1024px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    },
    {
      breakpoint: '480px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  const matchesTemplate = (match: MatchResponseDto) => {
    return (
      <div className="carousel-item">
        <MatchCard match={match} isInCarousel={true} />
      </div>
    );
  };

  const finishedMatchesTemplate = (match: MatchResponseDto) => {
    return (
      <div className="carousel-item">
        <MatchFinishedCard match={match} />
      </div>
    );
  };

  const SkeletonCard = (
    <div className="justify-content-center mt-3">
      <div className="border-round border-1 surface-border p-4 surface-card">
        <Skeleton width="50%" height="2rem" className="mb-2"></Skeleton>
        <div className="flex mb-3">
          <div>
            <Skeleton width="10rem" className="mb-2"></Skeleton>
          </div>
        </div>
        <Skeleton width="100%" height="150px"></Skeleton>
      </div>
    </div>
  );

  const renderCardWithCarousel = (
    title: string,
    emptySectionTitle: string, // Updated to accept JSX.Element
    template: (match: MatchResponseDto) => JSX.Element,
    data?: MatchResponseDto[]
  ) => {
    if (data === undefined || typeof data === 'undefined' || data.length === 0) {
      return null
    }

    if (isLoading || !data) {
      return SkeletonCard;
    }

    return (
      <>
        <div className="text-2xl font-bold pb-2" style={{ color: blue, fontFamily: 'TTRunsBold' }}>
          {title}
        </div>
        <Carousel className='home-carousel' value={data} numScroll={1} numVisible={3} showNavigators={!isMobile} responsiveOptions={responsiveOptions} itemTemplate={template} />
      </>
    );
  };

  const getGreeting = () => {
    const hour = new Date().getHours();

    if (hour < 12) {
      return 'Buenos días,';
    } else if (hour < 18) {
      return 'Buenas tardes,';
    } else {
      return 'Buenas noches,';
    }
  };

  return (
    <div className='feed-container'>
      <div style={{ maxWidth: 1200, flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 12 }}>
          <div style={{ backgroundColor: blue, padding: 10, borderRadius: 10, marginBottom: 6, flex: 1 }}>
            <div className="text-2xl font-bold pb-3" style={{ color: '#BDE83F', fontFamily: 'TTRunsBold' }}>
              {`${getGreeting()}  ${nickname}`}
            </div>
            <div className='flex flex-row flex-wrap justify-content-center align-items-center gap-3'>
              <div className='p-2' onClick={() => navigate('/add-match')}>
                <i
                  className="pi pi-plus-circle font-bold text-6xl flex align-items-center justify-content-center"
                  style={{ color: green }}
                />
                <div className="text-ml font-bold" style={{ color: green, fontFamily: 'GlacialRegular' }}>
                  Crear partido
                </div>
              </div>
              <div className='p-2' onClick={() => navigate('/available-matches')}>
                <i
                  className="pi pi-users font-bold text-6xl flex align-items-center justify-content-center"
                  style={{ color: green }}
                />
                <div className="text-ml font-bold" style={{ color: green, fontFamily: 'GlacialRegular' }}>
                  Unirme a un partido
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='body-container'>
          {renderCardWithCarousel('Cargar resultados', 'No hay partidos pendientes de cargar resultados', matchesTemplate, feedInformation?.matchesFinishedWithoutResult)}
          {renderCardWithCarousel(
            'Tus próximos partidos',
            'No tienes partidos disponibles, anótate a uno!',
            matchesTemplate,
            feedInformation?.nextMatches
          )}
          {renderCardWithCarousel('Resultados', 'Aún no finalizaste ningún partido, qué estás esperando?', finishedMatchesTemplate, feedInformation?.lastFinishedMatches)}
          <FAQ />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
