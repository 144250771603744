import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import './styles';
import LoginModal from './LoginModal';
import { Toast } from 'primereact/toast';
import { clearNotification } from '../store/notificationSlice';
import LoadingModal from './LoadingModal';
import RegisterModal from './RegisterModal';
import BottomTabBar from './BottomTabBar';
import icon from '../assets/images/icon.png';
import ForgotPasswordModal from './ForgotPasswordModal';
import HorizontalMenu from './HorizontalMenu';
import UserMenu from './UserMenu';

const Layout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector((state: RootState) => state.users.isAuthenticated);
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isForgotPasswordModalVisible, setIsForgotPasswordModalVisible] = useState(false);
  const toastRef = useRef<Toast>(null);
  const message = useSelector((state: RootState) => state.notification.message);
  const isLoading = useSelector((state: RootState) => state.notification.loading);
  const showRegisterModal = useSelector((state: RootState) => state.notification.showRegisterModal);
  const isMobile = window.innerWidth < 768;

  useEffect(() => {
    if (message) {
      toastRef.current?.show(message);
      dispatch(clearNotification());
    }
  }, [message, dispatch]);

  const location = useLocation();
  const isHome = location.pathname === '/';

  const showLoginModal = () => setLoginModalVisible(true);
  const showForgotPasswordModal = () => setIsForgotPasswordModalVisible(true);

  const menuItems = [
    { label: 'Inicio', icon: 'pi pi-fw pi-home', to: '/dashboard', visible: true },
    { label: 'Iniciar sesión', icon: 'pi pi-fw pi-sign-in', action: showLoginModal, visible: !isAuthenticated },
    { label: 'Unirme', icon: 'pi pi-fw pi-user-plus', to: '/signup', visible: !isAuthenticated },
    { label: 'Olvidé mi contraseña', icon: 'pi pi-fw pi-lock-open', action: showForgotPasswordModal, visible: !isAuthenticated },
    { label: 'Próximos partidos', icon: 'pi pi-fw pi-calendar', to: '/available-matches', visible: isAuthenticated },
    { label: 'Posiciones', icon: 'pi pi-fw pi-trophy', to: '/ranking', visible: isAuthenticated },
    { label: 'Resultados', icon: 'pi pi-fw pi-chart-bar', to: '/results', visible: isAuthenticated },
    { label: 'Notificaciones', icon: 'pi pi-fw pi-bell', to: '/notifications', visible: isAuthenticated },
  ];

  const menuLogo = <img src={icon} style={{ width: '135px', height: '70px' }} alt="PML logo" />;

  const onForgotPasswordPress = () => {
    setLoginModalVisible(false);
    setIsForgotPasswordModalVisible(true)
  }

  return (
    <div className="layout-container">
      <header>
        <HorizontalMenu 
          items={menuItems} 
          logo={menuLogo} 
          userMenu={<UserMenu />}
          isAuthenticated={isAuthenticated}
          isMobile={isMobile}
        />
      </header>
      <main className={`main-content ${isHome ? 'home' : ''} ${isMobile && isAuthenticated ? 'mobile-authenticated' : ''}`}>
        <Outlet />
      </main>
      {isMobile && isAuthenticated ? <BottomTabBar /> : null}
      <LoadingModal isLoading={isLoading} />
      <RegisterModal visible={showRegisterModal} />
      <Toast ref={toastRef} />
      <LoginModal visible={isLoginModalVisible} onHide={() => setLoginModalVisible(false)} onForgotPasswordPress={onForgotPasswordPress} />
      <ForgotPasswordModal visible={isForgotPasswordModalVisible} onHide={() => setIsForgotPasswordModalVisible(false)} />
    </div>
  );
};

export default Layout;