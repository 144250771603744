import { AppDispatch } from '../../store';
import { RegisterFormData } from '../../store/registrationSlice';
import apiClient from '../apiClient';
import { DivisionResponseDto, FeedDto, UserDivisionDto, UserResponseDto, UserSeasonDivisionDto, UserSeasonDto } from '../apiTypes';

export const register = (user: RegisterFormData) => async (dispatch: AppDispatch): Promise<void> => {
  const formData = new FormData();

  formData.append('Name', user.name);
  formData.append('LastName', user.lastName);
  formData.append('Nickname', user.nickname);
  formData.append('PhoneNumber', user.phoneNumber);
  formData.append('Email', user.email);
  if (user.birthDate) {
    formData.append('BirthDate', user.birthDate.toISOString()); // Convertir la fecha a string ISO
  }
  formData.append('BirthCountry', user.birthCountry.name);
  formData.append('Password', user.password);
  formData.append('ConfirmPassword', user.confirmPassword);
  if (user.profilePicture) {
    formData.append('ProfilePicture', user.profilePicture);
  }
  formData.append('PadelExperience', user.padelExperience);
  formData.append('Department', user.department);
  if (user.gender !== undefined) {
    formData.append('Gender', user.gender.toString());
  }
  if (user.skilledHand !== undefined) {
    formData.append('SkilledHand', user.skilledHand.toString());
  }
  if (user.courtPosition !== undefined) {
    formData.append('CourtPosition', user.courtPosition.toString());
  }

  await apiClient.post<{ token: string }>('/users/register', formData);
  if (process.env.REACT_APP_ENV === 'production') {
    sendEventToFacebook(user);
  }
};

export const getFeedInfo = async (userId: string): Promise<FeedDto> => {
  const response = await apiClient.get<FeedDto>(`/users/${userId}/feed`);
  return response.data;
};

export const getInfo = async (userId: string): Promise<UserResponseDto> => {
  const response = await apiClient.get<UserResponseDto>(`/Users/${userId}`);
  return response.data;
};

export const fetchUserDivisions = async (userId: string): Promise<DivisionResponseDto[]> => {
  const response = await apiClient.get<DivisionResponseDto[]>(`/divisions/${userId}`);
  return response.data;
};

export const getUsers = async (): Promise<UserSeasonDivisionDto[]> => {
  const response = await apiClient.get<UserSeasonDivisionDto[]>(`/users`);
  return response.data;
};

export const addUsersToSeasons = async (usersSeasons: UserSeasonDto[]): Promise<void> => {
  await apiClient.post('/seasons/addUsersToSeasons', usersSeasons);
};

export const activeUsers = async (userIds: string[]): Promise<void> => {
  await apiClient.post('/users/activeUsers', userIds);
};

export const addUsersToDivisions = async (usersDivisions: UserDivisionDto[]): Promise<void> => {
  await apiClient.post('/divisions/addUsersToDivisions', usersDivisions);
};

const hashString = async (str: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(str);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

const sendEventToFacebook = async (user: RegisterFormData) => {
  const hashedEmail = await hashString(user.email);
  const hashedPhone = await hashString(user.phoneNumber);
  const hashedCountry = await hashString(user.birthCountry.name);
  const hashedLastName = await hashString(user.lastName);

  const accessToken = process.env.REACT_APP_FACEBOOK_ACCESS_TOKEN;

  const eventData = {
    data: [
      {
        event_name: "RegistroExitoso",
        event_time: Math.floor(Date.now() / 1000),
        action_source: "website",
        user_data: {
          em: [hashedEmail],
          ph: [hashedPhone],
          country: [hashedCountry],
          ln: [hashedLastName]
        },
        custom_data: {
          nickname: user.nickname
        }
      }
    ],
    access_token: accessToken
  };

  try {
    await apiClient.post('https://graph.facebook.com/v20.0/1049885480134793/events', eventData);
  } catch (error) {
    console.error('Error enviando datos a la API de Conversiones:', error);
  }
};
