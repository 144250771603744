import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '.';
import { register as apiRegister } from '../api/user/usersApi'
import { showNotification, setLoading, setShowRegisterModal } from './notificationSlice';
import { AxiosError } from 'axios';
import { Country } from '../components/CountryDropdown';
import { getErrorMessage } from '../helpers/manageErrors';

export interface RegisterFormData {
  name: string;
  lastName: string;
  nickname: string;
  phoneNumber: string;
  email: string;
  birthDate: Date | null;
  birthCountry: Country;
  password: string;
  confirmPassword: string;
  profilePictureId: string;
  profilePicture: File | null;
  department: string;
  gender: number | undefined;
  padelExperience: string;
  skilledHand: number | undefined;
  courtPosition: number | undefined;
}

export const initialState: RegisterFormData = {
  name: '',
  lastName: '',
  nickname: '',
  phoneNumber: '',
  email: '',
  birthDate: null,
  birthCountry: { name: 'Uruguay', code: 'UY' },
  password: '',
  confirmPassword: '',
  profilePictureId: '',
  padelExperience: '',
  department: '',
  gender: undefined,
  skilledHand: undefined,
  courtPosition: undefined,
  profilePicture: null,
};

const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    setFormData(state, action: PayloadAction<Partial<RegisterFormData>>) {
      return { ...state, ...action.payload };
    },
    resetFormData() {
      return initialState;
    },
    registerSuccess: (state) => {

    },
    registerFailure: (state) => {

    },
  },
});

export const register = (data: RegisterFormData, succesCallback: () => void) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true))
    await (await apiRegister(data))(dispatch);
    dispatch(setShowRegisterModal(true));
    succesCallback();
    await dispatch(setFormData(initialState));
  } catch (error) {
    // let errorMessage = 'Ocurrió un error inesperado.';

    // if (error instanceof AxiosError) {
    //   if (error.response) {
    //     // If the response data is an array, use the first item's description.
    //     if (Array.isArray(error.response.data) && error.response.data[0]) {
    //       errorMessage = error.response.data[0].description || 'Ocurrió un error al procesar tu solicitud.';
    //     } else if (typeof error.response.data === 'string') {
    //       // If the response data is a string, use it directly.
    //       errorMessage = error.response.data;
    //     } else {
    //       // If it's an object, check if it has a message or description property.
    //       errorMessage = error.response.data.description || 'Ocurrió un error al procesar tu solicitud.';
    //     }
    //   } else if (error.request) {
    //     // The request was made but no response was received
    //     errorMessage = 'No se pudo establecer conexión con el servidor. Verifica tu conexión a internet.';
    //   } else {
    //     // Something happened in setting up the request that triggered an error
    //     errorMessage = error.message;
    //   }
    // }

    dispatch(showNotification({ severity: 'error', summary: 'Ocurrió un error al registrar tu usuario.', detail: getErrorMessage(error), sticky: true }));
  } finally {
    dispatch(setLoading(false))
  }
};


export const { setFormData, resetFormData, registerSuccess, registerFailure } = registrationSlice.actions;
export default registrationSlice.reducer;
