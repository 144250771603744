import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserAvailableMatches } from '../store/matchesSlice';
import { AppDispatch, RootState } from '../store';
import './styles/AvailableMatches.css';
import MatchCard from '../components/MatchCard';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { DivisionResponseDto } from '../api/apiTypes';
import { useNavigate } from 'react-router-dom';
import { getUserDivisions } from '../store/usersSlice';
import { Skeleton } from 'primereact/skeleton';
import { blue, green } from '../constants/colors';

const AvailableMatches: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const matches = useSelector((state: RootState) => state.matches.userAvailableMatches);
  const userId = useSelector((state: RootState) => state.users.userId);
  const userDivisions = useSelector((state: RootState) => state.users.userDivisions);
  const loadingMatches = useSelector((state: RootState) => state.matches.loading);
  const loadingDivisions = useSelector((state: RootState) => state.users.loading);

  const userDivision = userDivisions && userDivisions.length > 0 ? userDivisions[0] : undefined;
  const [selectedDivision, setSelectedDivision] = useState<DivisionResponseDto | undefined>(userDivision);

  useEffect(() => {
    if (userDivision && !selectedDivision) {
      setSelectedDivision(userDivision);
    }
  }, [userDivision, selectedDivision]);

  useEffect(() => {
    const getUserDivisionsAsync = async () => {
      if (userId) {
        await dispatch(getUserDivisions(userId as string));
        if (userDivisions !== null && userDivisions.length > 0) {
          setSelectedDivision(userDivisions[0]);
        }
      }
    };
    getUserDivisionsAsync();
  }, []);

  useEffect(() => {
    if (selectedDivision !== undefined && selectedDivision.id !== null) dispatch(fetchUserAvailableMatches(userId as string, selectedDivision.id));
  }, [selectedDivision, userId, dispatch]);

  return (
    <div className="feed-container">
      <div style={{ maxWidth: 1200, flex: 1 }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 12 }}>
          <div style={{ backgroundColor: blue, padding: 10, borderRadius: 10, marginBottom: 6, flex: 1 }}>
            <div className="text-2xl font-bold pb-3" style={{ color: '#BDE83F', fontFamily: 'TTRunsBold' }}>
              Partidos
            </div>
            <div className='flex flex-column'>
              {!loadingDivisions && userDivisions !== null ? (
                <>
                  <div className="grid grid-nogutter" style={{ borderRadius: 8, paddingInline: 6, marginBlock: 8 }}>
                    <div className="col-4 flex align-items-center justify-content-start">
                      <div className="text-xl" style={{ color: '#BDE83F' }}>
                        Categoria
                      </div>
                    </div>
                    <div className="col">
                      <Dropdown
                        value={selectedDivision}
                        onChange={(e: DropdownChangeEvent) => setSelectedDivision(e.value)}
                        options={userDivisions}
                        optionLabel="name"
                        placeholder="Categoria"
                        className="w-full md:w-14rem my-2 filters-dropdown"
                        checkmark={true}
                        highlightOnSelect={false}
                      />
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        backgroundColor: green,
                        padding: 10,
                        borderRadius: 8,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        navigate('/add-match');
                      }}
                    >
                      <div
                        className="text-xl font-bold"
                        style={{
                          color: blue,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span style={{ marginRight: 8 }}>Crear partido</span>
                        <i
                          className="pi pi-plus-circle font-bold text-2xl"
                          style={{ color: blue }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <DivisionFilterSkeleton />
              )}
            </div>
          </div>
        </div>
        <div className="body-results-container">
          {matches && matches.length > 0 ? (
            matches.map((match, index) => (
              <div key={index} className="available-matches-container">
                <MatchCard match={match} isLoading={loadingMatches || loadingDivisions} />
              </div>
            ))
          ) : (
            <h2>No hay resultados disponibles para la búsqueda</h2>
          )}
        </div>
      </div>
    </div>
  );
};

const DivisionFilterSkeleton: React.FC = () => {
  return (
    <div className="division-filter-container">
      <Skeleton width="15rem" height="2rem" />
    </div>
  );
};

export default AvailableMatches;
