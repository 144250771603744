import axios from 'axios';
import { CancelMatchDto, CourtPosition, LoadMatchResultDto, Match, MatchResponseDto, JoinMatchDto } from '../apiTypes';
import apiClient from '../apiClient';
import { MatchData } from '../../pages/AddMatch';

const BASE_URL = 'your_api_base_url';

export const getUserAvailableMatches = async (userId: string, divisionId?: number): Promise<MatchResponseDto[]> => {
  const url = divisionId !== -1 ? `/Matches/user/${userId}?divisionId=${divisionId}` : `/Matches/user/${userId}`
  const response = await apiClient.get<MatchResponseDto[]>(url);
  return response.data;
};

export const getMatchDetail = async (matchId: string, userId: string): Promise<MatchResponseDto> => {
  const response = await apiClient.get<MatchResponseDto>(`/Matches/${matchId}/${userId}`);
  return response.data;
};

export const getFinishedMatchesBySeasonAndDivision = async (seasonId: number, divisionId: number): Promise<MatchResponseDto[]> => {
  const response = await apiClient.get<MatchResponseDto[]>(`/Matches/finishedMatches/season/${seasonId}/division/${divisionId}`);
  return response.data;
};

export const getDivisionPendingMatches = async (divisionId: string): Promise<MatchResponseDto[]> => {
  const response = await apiClient.get<MatchResponseDto[]>(`/Matches/divisionPendingMatches/${divisionId}`);
  return response.data;
};

export const createMatch = async (match: MatchData): Promise<void> => {
  await apiClient.post('/Matches', match);
};

export const cancelMatch = async (cancelMatchDto: CancelMatchDto): Promise<void> => {
  await apiClient.put('/Matches/cancelMatch', cancelMatchDto);
};

export const joinMatch = async (joinMatchDto: JoinMatchDto): Promise<void> => {
  await apiClient.post('/Matches/joinMatch', joinMatchDto);
};

export const removeFromMatchService = async (matchId: string, userId: string): Promise<void> => {
  await apiClient.post('/Matches/removeFromMatchService', { matchId, userId });
};

export const getMatchById = async (matchId: string): Promise<Match> => {
  const response = await axios.get<Match>(`${BASE_URL}/Matches/${matchId}`);
  return response.data;
};

export const getMatchesByUserId = async (userId: string): Promise<Match[]> => {
  const response = await axios.get<Match[]>(`${BASE_URL}/Matches/user/${userId}`);
  return response.data;
};

export const getMatchesBySeasonAndDivision = async (seasonId: number, divisionId: number): Promise<Match[]> => {
  const response = await axios.get<Match[]>(`${BASE_URL}/Matches/season/${seasonId}/division/${divisionId}`);
  return response.data;
};

export const loadMatchResult = async (loadMatchResultDto: LoadMatchResultDto): Promise<void> => {
  await apiClient.put('/Matches/loadMatchResult', loadMatchResultDto);
};


export const updateMatchForUser = async (matchId: string, userId: string): Promise<void> => {
  await axios.put(`${BASE_URL}/Matches/${matchId}/user/${userId}`);
};
