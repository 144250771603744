import { useState, useEffect } from 'react';
import { blue, green } from '../constants/colors';
import racket from '../racket.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

export default function BottomTabBar() {
  const [activeTab, setActiveTab] = useState('inicio');
  const userId = useSelector((state: RootState) => state.users.userId);
  const navigate = useNavigate();
  const location = useLocation();

  const tabs = [
    { id: 'inicio', Icon: 'pi pi-fw pi-home', label: 'Inicio', path: '/dashboard' },
    { id: 'posiciones', Icon: 'pi pi-fw pi-trophy', label: 'Posiciones', path: '/ranking' },
    {
      id: 'partidos',
      Icon: racket,
      label: 'Partidos',
      path: '/available-matches',
    },
    { id: 'resultados', Icon: 'pi pi-fw pi-chart-bar', label: 'Resultados', path: '/results' },
    { id: 'perfil', Icon: 'pi pi-fw pi-user', label: 'Perfil', path: `/user-information/${userId}` },
  ];

  useEffect(() => {
    const activePath = location.pathname;
    const matchingTab = tabs.find(tab => activePath.startsWith(tab.path));
    if (matchingTab) {
      setActiveTab(matchingTab.id);
    } else {
      setActiveTab('');
    }
  }, [location, tabs]);

  return (
    <div className="fixed bottom-0 left-0 right-0 border-round-top-3xl h-4rem" style={{ backgroundColor: blue }}>
      <nav className="flex align-items-end justify-content-between px-4 max-w-md mx-auto relative">
        {tabs.map((tab, index) => {
          const Icon = tab.Icon;
          const isMiddle = index === Math.floor(tabs.length / 2);
          const isActive = activeTab === tab.id;
          return (
            <div
              key={tab.id}
              className="flex flex-column align-items-center"
              onClick={() => {
                setActiveTab(tab.id);
                navigate(tab.path);
              }}>
              <div
                className={`flex flex-column align-items-center justify-content-center ${isMiddle ? 'border-circle' : 'bg-transparent'} w-4rem h-4rem transition-duration-200 transition-transform transition-opacity`}
                style={{
                  color: isActive ? 'white' : 'rgba(255, 255, 255, 0.7)',
                  backgroundColor: isMiddle ? green : blue,
                  position: isMiddle ? 'relative' : 'static',
                  top: isMiddle ? '-2rem' : '0',
                  transform: isActive ? 'scale(1.1)' : 'scale(1)',
                  opacity: isActive && !isMiddle ? 0.9 : 1,
                  transition: 'transform 0.2s ease, opacity 0.2s ease',
                }}>
                {isMiddle ? (
                  <img src={Icon} alt="Partidos Icon" className={`w-6 h-6 pt-3 ${isActive ? 'scale-110' : ''}`} style={{ transition: 'transform 0.2s ease' }} />
                ) : (
                  <i className={`${isMiddle ? 'text-blue-700' : 'text-white'} ${isActive ? 'text-lg font-bold' : ''} mb-1 ${Icon}`} />
                )}
                <span
                  className={`text-xs ${isActive ? 'font-bold text-sm' : 'font-medium'} text-white`}
                  style={{
                    position: isMiddle ? 'relative' : 'static',
                    top: isMiddle ? '0.5rem' : '0',
                  }}>
                  {tab.label}
                </span>
              </div>
            </div>
          );
        })}
      </nav>
    </div>
  );
}
