import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { getInfo } from '../api/user/usersApi';
import { useParams } from 'react-router-dom';
import { CourtPosition, UserResponseDto } from '../api/apiTypes';
import './styles/UserInformation.css';

const UserInformationScreen: React.FC = () => {
  const [user, setUser] = useState<UserResponseDto | null>(null);
  const { userId } = useParams<{ userId: string }>();
  const userImage = userId ? `${process.env.REACT_APP_STORAGE_URL}${userId.toLowerCase()}` : '';

  useEffect(() => {
    const getUserInfoAsync = async () => {
      if (userId) {
        const userData = await getInfo(userId);
        setUser(userData);
      }
    };
    getUserInfoAsync();
  }, [userId]);

  const skilledHandLabel = user?.skilledHand === 1 ? 'Derecha' : 'Zurda';
  const genderLabel = user?.gender === 1 ? 'Hombre' : 'Mujer';
  const getCourtPositionLabel = (courtPosition: CourtPosition): string => {
    switch (courtPosition) {
      case CourtPosition.Drive:
        return 'Drive';
      case CourtPosition.Backwards:
        return 'Revés';
      case CourtPosition.PreferablyDrive:
        return 'Preferiblemente Drive';
      case CourtPosition.PreferablyBackwards:
        return 'Preferiblemente Revés';
      case CourtPosition.NoPreference:
        return 'Sin Preferencia';
      default:
        return '';
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="profile-container">
      <div className="profile-card blue-section">
        <div className="avatar">
          <img src={userImage} alt={user.name} />
        </div>
        <div className="name-section">
          <h1>{`${user.name} ${user.lastName}`}</h1>
          <p>{user.nickname}</p>
        </div>
      </div>

      <div className="profile-card info-section">
        <InfoItem icon={'pi-envelope'} label="Email" value={user.email} />
        <InfoItem icon={'pi-user'} label="Género" value={genderLabel} />
        <InfoItem icon={'pi-phone'} label="Celular" value={user.phoneNumber} />
        <InfoItem icon={'pi-calendar'} label="Fecha de nacimiento" value={format(new Date(user.birthDate), 'dd MMM yyyy')} />
        <InfoItem icon={'pi-map'} label="País de nacimiento" value={user.birthCountry} />
        <InfoItem icon={'pi-map-marker'} label="Vive en" value={user.department as any} />
        <InfoItem icon={'pi-star'} label="Mano hábil" value={skilledHandLabel} />
        <InfoItem icon={'pi-table'} label="Posición preferida" value={getCourtPositionLabel(user.courtPosition)} />
      </div>
    </div>
  );
};

const InfoItem: React.FC<{ icon: string; label: string; value: string }> = ({ icon, label, value }) => {
  return (
    <div className="info-item">
      {icon && <div className={`icon pi ${icon}`} />}
      <div>
        <p className="label">{label}</p>
        <p className="value">{value || 'No especificado'}</p>
      </div>
    </div>
  );
};

export default UserInformationScreen;
