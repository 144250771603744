import moment from 'moment';

export function isWithinNext24Hours(target: string) {
  const now = moment().utc().local(); // Current local time
  const targetDate = moment.utc(target).local(); // Convert target date to local time

  const timeDifference = targetDate.diff(now, 'milliseconds');
  const millisecondsIn24Hours = 24 * 60 * 60 * 1000;

  return timeDifference >= 0 && timeDifference <= millisecondsIn24Hours;
}