
export interface Results {
    game1: number | null;
    game2: number | null;
    game3?: number | null;
}

// Add this function to validate the set scores
const isValidSetScore = (firstScore: number | null | undefined, secondScore: number | null | undefined): boolean => {
    if (firstScore === null || secondScore === null || firstScore === undefined || secondScore === undefined) return false;
    if (firstScore < 0 || secondScore < 0) return false;

    // Valid regular set win conditions.
    if ((firstScore === 6 && secondScore <= 4) || (secondScore === 6 && firstScore <= 4)) return true;

    // Allow 7-5 or 5-7 scores.
    if ((firstScore === 7 && secondScore === 5) || (secondScore === 7 && firstScore === 5)) return true;

    // Tiebreak: 7-6 or 6-7 is valid
    if ((firstScore === 7 && secondScore === 6) || (secondScore === 7 && firstScore === 6)) return true;

    return false;
};

export const determineWinners = (firstPair: Results, secondPair: Results, hasThreeSets: boolean): 0 | 1 | null => {
    let firstPairWins = 0;
    let secondPairWins = 0;

    const isGame1Valid = isValidSetScore(firstPair.game1, secondPair.game1);
    const isGame2Valid = isValidSetScore(firstPair.game2, secondPair.game2);
    const isGame3Valid = hasThreeSets ? isValidSetScore(firstPair.game3, secondPair.game3) : true;

    if (!isGame1Valid || !isGame2Valid || (hasThreeSets && !isGame3Valid)) {
        return null;
    }

    if (firstPair.game1! > secondPair.game1!) firstPairWins++;
    else secondPairWins++;

    if (firstPair.game2! > secondPair.game2!) firstPairWins++;
    else secondPairWins++;

    if (!hasThreeSets) {
        return firstPairWins > secondPairWins ? 0 : secondPairWins > firstPairWins ? 1 : null;
    }

    if (firstPairWins === secondPairWins) {
        if (firstPair.game3! > secondPair.game3!) return 0;
        else if (secondPair.game3! > firstPair.game3!) return 1;
    }

    return null;
};
