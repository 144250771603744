import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch } from '.';

interface NotificationState {
  message: { severity: 'success' | 'info' | 'warn' | 'error'; summary: string; detail?: string, sticky?: boolean, life?: number } | null;
  loading: boolean;
  showRegisterModal: boolean;
}

const initialState: NotificationState = {
  message: null,
  loading: false,
  showRegisterModal: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification: (
      state,
      action: PayloadAction<{ severity: 'success' | 'info' | 'warn' | 'error'; summary: string; detail?: string, sticky?: boolean, life?: number }>
    ) => {
      state.message = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.loading = action.payload;
    },
    clearNotification: (state) => {
      state.message = null;
    },
    setShowRegisterModal: (state, action: PayloadAction<boolean>) => {
      state.showRegisterModal = action.payload;
    },
  },
});

export const { showNotification, clearNotification, setLoading, setShowRegisterModal } = notificationSlice.actions;

export const setRegisterModalVisibility = (visible: boolean) => (dispatch: AppDispatch) => {
  try {
    dispatch(setShowRegisterModal(visible))
  } catch (error) {
    console.log(error)
  }
}

export default notificationSlice.reducer;
