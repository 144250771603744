import React from 'react';
import { green } from '../../constants/colors';
import { confirmPopup } from 'primereact/confirmpopup';
import './players.css'
import { CourtPosition } from '../../api/apiTypes';


type EmptySpotProps = {
    isInCarousel?: boolean;
    userIsJoined: boolean;
    fontColor?: string;
    teamSide?: 0 | 1;
    matchPosition?: CourtPosition.Drive | CourtPosition.Backwards;
    onJoinMatch: (matchPosition?: CourtPosition.Drive | CourtPosition.Backwards, teamSide?: 0 | 1) => void;
};

const EmptySpot: React.FC<EmptySpotProps> = ({ isInCarousel, userIsJoined, fontColor = "#FFFFFF", teamSide, matchPosition, onJoinMatch }) => {

    const isTeamMode = matchPosition !== undefined && teamSide !== undefined;
    const teamLabel = teamSide === 0 ? 'Primer' : 'Segunda'
    const positionLabel = matchPosition === CourtPosition.Drive ? 'Drive' : 'Revés'
    const disclaimer = 'Estas seguro que quieres unirte al partido?';
    const teamModeDisclaimer = `Estas seguro que quieres unirte al partido jugando de ${positionLabel} en la ${teamLabel} pareja?`;

    const onJoinMatchClick = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: isTeamMode ? teamModeDisclaimer : disclaimer,
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => onJoinMatch(matchPosition, teamSide),
            acceptLabel: 'Si',
            dismissable: true
        });
    };

    const textSize = isInCarousel ? 'text-5xl' : 'text-6xl'

    return (
        <div className='customCol col cursor-pointer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} onClick={userIsJoined ? undefined : onJoinMatchClick}>
            <div className={`pi pi-plus-circle ${textSize}`} style={{ color: green }} />
            {isTeamMode && < div className='text-m font-bold' style={{ color: green }}>{`${positionLabel}`}</div>}
            <div className='text-m font-bold' style={{ color: green }}>{'Libre'}</div>
        </div >
    );
};

export default EmptySpot;
