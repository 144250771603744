import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import { Home, Dashboard, SignUp, AddMatch, MatchInfo, AvailableMatches, Ranking, Results, MatchLoadResult, Notifications, UserInformation } from './pages';
import PrivateRoute from './navigation/PrivateRoute';
import Admin from './pages/Admin';
import Regulation from './pages/Regulation';
import CreatePassword from './pages/CreatePassword';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/reset-password" element={<CreatePassword />} />
          <Route
            index
            element={
              <PrivateRoute redirectTo="/dashboard" publicOnly>
                <Home />
              </PrivateRoute>
            }
          />
          <Route path="signup" element={<SignUp />} />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="add-match"
            element={
              <PrivateRoute>
                <AddMatch />
              </PrivateRoute>
            }
          />
          <Route
            path="available-matches"
            element={
              <PrivateRoute>
                <AvailableMatches />
              </PrivateRoute>
            }
          />
          <Route
            path="ranking"
            element={
              <PrivateRoute>
                <Ranking />
              </PrivateRoute>
            }
          />
          <Route
            path="results"
            element={
              <PrivateRoute>
                <Results />
              </PrivateRoute>
            }
          />
          <Route
            path="match-info/:matchId"
            element={
              <PrivateRoute>
                <MatchInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="match-load-result/:matchId"
            element={
              <PrivateRoute>
                <MatchLoadResult />
              </PrivateRoute>
            }
          />
          <Route
            path="notifications"
            element={
              <PrivateRoute>
                <Notifications />
              </PrivateRoute>
            }
          />
          <Route
            path="user-information/:userId"
            element={
              <PrivateRoute>
                <UserInformation />
              </PrivateRoute>
            }
          />
          <Route
            path="admin"
            element={
              <PrivateRoute>
                <Admin />
              </PrivateRoute>
            }
          />
          <Route
            path="regulation"
            element={
              <PrivateRoute>
                <Regulation />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
