import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { Toast } from 'primereact/toast';
import { useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FormInput } from '../components';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { resetPasswordAction } from '../store/usersSlice';

interface CreatePasswordFormData {
    newPassword: string;
    confirmPassword: string;
}

const CreatePassword: React.FC = () => {
    const { control, handleSubmit, formState: { errors }, watch, trigger } = useForm<CreatePasswordFormData>();
    const [loading, setLoading] = useState(false);
    const toast = useRef<Toast>(null);
    const navigate = useNavigate();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);

    const search = location.search;
    const tokenMatch = search.match(/token=([^&]*)/);
    let token: string | null = null;
    if (tokenMatch && tokenMatch[1]) {
        token = tokenMatch[1];
    }
    
    const email = queryParams.get('email');

    const dispatch = useDispatch<AppDispatch>();

    const password = watch('newPassword');
    const confirm = watch('confirmPassword');

    useEffect(() => {
        if (!token || !email || token === null || email === null) {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Enlace de restablecimiento inválido', life: 3000 });
            navigate('/');
        }
    }, [token, email, navigate]);

    useEffect(() => {
        if (password && confirm) {
            trigger('confirmPassword');
        }
    }, [password, confirm, trigger]);

    const onSubmit = async (data: CreatePasswordFormData) => {
        try {
            setLoading(true);
            await dispatch(resetPasswordAction(email as string, token as string, data.newPassword))
            setTimeout(() => navigate('/'), 3000);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex align-items-center justify-content-center">
            <Toast ref={toast} />
            <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <h2 className="text-center mb-4" style={{ fontFamily: 'TTRunsBold' }}>Establece tu nueva contraseña</h2>
                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                    <FormInput
                        name="newPassword"
                        control={control}
                        label="Nueva Contraseña"
                        placeholder="Nueva Contraseña"
                        type="password"
                        defaultValue=""
                        rules={{
                            required: 'La contraseña es obligatoria.',
                            minLength: { value: 8, message: 'La contraseña debe tener al menos 8 caracteres.' },
                        }}
                        errors={errors}
                        hasFloatLabel={false}
                        className="w-full mb-3"
                        toggleMask={true}
                        helpText="La contraseña debe tener al menos 8 caracteres."
                    />

                    <FormInput
                        name="confirmPassword"
                        control={control}
                        label="Confirmar Contraseña"
                        placeholder="Confirmar Contraseña"
                        type="password"
                        defaultValue=""
                        rules={{
                            required: 'La confirmación de contraseña es obligatoria.',
                            validate: (value: string) => value === password || 'Las contraseñas no coinciden.',
                            minLength: { value: 8, message: 'La contraseña debe tener al menos 8 caracteres.' },
                        }}
                        errors={errors}
                        hasFloatLabel={false}
                        className="w-full mb-3"
                        toggleMask={true}
                        helpText="La contraseña debe tener al menos 8 caracteres."
                        feedback={false}
                    />

                    <Button type="submit" label="Cambiar Contraseña" icon="pi pi-check" className="primary-button w-full" loading={loading} />
                </form>
            </div>
        </div>
    );
};

export default CreatePassword;