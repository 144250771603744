import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store'; // Adjust import according to your store setup

interface PrivateRouteProps {
  children: React.ReactNode;
  redirectTo?: string;
  publicOnly?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, redirectTo = '/', publicOnly = false }) => {
  const isAuthenticated = useSelector((state: RootState) => state.users.isAuthenticated);

  if (isAuthenticated && publicOnly) {
    return <Navigate to={redirectTo} />;
  }

  return isAuthenticated || publicOnly ? <>{children}</> : <Navigate to="/" />;
};

export default PrivateRoute;
