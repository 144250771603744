import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '.';
import { BasicInformationDto, ClubResponseDto, DivisionResponseDto, SeasonResponseDto } from '../api/apiTypes';
import { fetchConfiguration } from '../api/configuration/configurationApi';

interface ConfigState {
  seasons?: SeasonResponseDto[];
  divisions?: DivisionResponseDto[];
  clubs?: ClubResponseDto[];
}

const initialState: ConfigState = {
  seasons:[],
  divisions: [],
  clubs: []
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    fetchConfigurationSuccess: (state,  action: PayloadAction<BasicInformationDto>) => {
      state.clubs = action.payload.clubs;
      state.divisions = action.payload.divisions;
      state.seasons = action.payload.seasons;
    },
    fetchConfigurationFailure: (state, action: PayloadAction<string>) => {
    },
  },
});

export const { fetchConfigurationSuccess, fetchConfigurationFailure } = configSlice.actions;

export const getConfiguration = () => async (dispatch: AppDispatch) => {
  try {
    const response = await fetchConfiguration();
    dispatch(fetchConfigurationSuccess(response));
  } catch (error) {
    dispatch(fetchConfigurationFailure('An error has occurred fetching the configuration.'));
  }
};

export const divisionName = (id: number) => (state: RootState): string | undefined => {
    const division = state.configuration.divisions?.find(division => division.id === id);
    return division?.name;
  };

export default configSlice.reducer;
