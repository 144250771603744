import React from "react";
import { Dropdown, DropdownChangeEvent, DropdownProps } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import './styles/flags.css'

export interface Country {
    name: string;
    code: string;
}

interface CountryDropdownProps {
    value: Country;
    onChange: (value: Country) => void;
    countries: Country[];
    placeholder?: string;
    errors?: any;
}

const CountryDropdown: React.FC<CountryDropdownProps> = ({ value, onChange, countries, placeholder, errors }) => {

    const selectedCountryTemplate = (option: Country, props: DropdownProps) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                    <div>{option.name}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option: Country) => (
        <div className="flex align-items-center">
            <img alt={option.name} src={`https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png`} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
            <div>{option.name}</div>
        </div>
    );

    return (
        <Dropdown
            value={value}
            options={countries}
            optionLabel="name"
            placeholder={placeholder}
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            onChange={(e: DropdownChangeEvent) => onChange(e.value)}
            className={classNames({ 'p-invalid': errors })}
        />
    );
};

export default CountryDropdown;
