import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Ranking } from '../api/apiTypes';
import { getRanking } from '../api/ranking/rankingApi';
import { AppDispatch } from '.';

interface RankingState {
    ranking: Ranking | null;
    loading: boolean;
    error: string | null;
  }

const initialState: RankingState = {
    ranking: null,
    loading: false,
    error: null,
  };


const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getRankingFailure: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    getRankingSuccess: (state, action: PayloadAction<Ranking>) => {
      state.ranking = action.payload;
    },
  },
});

export const { setLoading, getRankingFailure, getRankingSuccess } = rankingSlice.actions;


export const fetchRanking = (divisionId: number, seasonId?: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getRanking(divisionId, seasonId);
    dispatch(getRankingSuccess(response));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(setLoading(false));
  }
};

export default rankingSlice.reducer;
