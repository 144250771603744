import React from 'react';
import './styles/MatchPlayersInfo.css';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { Button } from 'primereact/button';
import { Skeleton } from 'primereact/skeleton';
import { CourtPosition, UserIdNameDto } from '../api/apiTypes';
import PlayersPairs from './Players/PlayersPairs';
import { WhatsappShareButton, } from 'react-share'

type MatchPlayersInfoProps = {
    isLoading: boolean;
    spotsAvailable: number;
    userIsJoined?: boolean;
    showDisclaimer: boolean;
    showCancelMatch: boolean;
    showPlayers?: boolean;
    showPlayersTeamMode?: boolean;
    player1?: UserIdNameDto;
    player2?: UserIdNameDto;
    player3?: UserIdNameDto;
    player4?: UserIdNameDto;
    shareMessage: string;
    onJoinMatch: (matchPosition?: CourtPosition.Drive | CourtPosition.Backwards, teamSide?: 0 | 1) => void;
    onUserRemovesFromMatch: () => void;
    onCancelMatch: () => void;
};

const MatchPlayersInfo: React.FC<MatchPlayersInfoProps> = (props) => {
    const { isLoading, spotsAvailable, userIsJoined, showDisclaimer, showCancelMatch, showPlayers, showPlayersTeamMode, player1, player2, player3, player4, shareMessage, onJoinMatch, onUserRemovesFromMatch, onCancelMatch } = props;

    const onCancelMatchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estas seguro que quieres cancelar el partido?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => onCancelMatch(),
            acceptLabel: 'Si',
            dismissable: true
        });
    };

    if (isLoading) {
        return (
            <div className='players-container px-4'>
                <Skeleton width="60%" height="1rem" className="mb-3" />
                <div className="flex justify-content-between mb-3">
                    {[...Array(4)].map((_, index) => (
                        <div key={index} className="flex flex-column align-items-center">
                            <Skeleton shape="circle" size="3rem" className="mb-2" />
                            <Skeleton width="4rem" height="1rem" />
                        </div>
                    ))}
                </div>
                <Skeleton width="100%" height="3rem" className="mb-3" />
            </div>
        );
    }

    return (
        <div className="players-container">
            <span className='title text-xl'>Jugadores</span>
            <PlayersPairs
                theme='blue'
                spotsAvailable={spotsAvailable}
                userIsJoined={userIsJoined}
                userCanCancel={showCancelMatch || spotsAvailable === 0}
                onJoinMatch={onJoinMatch}
                onUserRemovesFromMatch={onUserRemovesFromMatch}
                showPlayers={showPlayers}
                showPlayersTeamMode={showPlayersTeamMode}
                player1={player1}
                player2={player2}
                player3={player3}
                player4={player4}
            />
            {showDisclaimer && (
                <div className='px-4'>
                    <span className='text-m text-white font-italic font-bold'>
                        {'Las parejas se dan a conocer, una vez que el partido esté completo, 24 hs antes del comienzo.'}
                    </span>
                </div>
            )}
            <div className='flex-1 pt-3' style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '12px'
            }}>
                {showCancelMatch && (
                    <Button
                        severity="danger"
                        onClick={onCancelMatchClick}
                        style={{
                            borderRadius: 12,
                            height: 35,
                            width: 220,
                            justifyContent: 'center',
                            margin: 0
                        }}
                    >
                        Cancelar partido
                    </Button>
                )}
                <WhatsappShareButton
                    url={shareMessage}
                    style={{ margin: 0 }}
                >
                    <Button
                        severity="secondary"
                        style={{
                            borderRadius: 12,
                            height: 35,
                            // width: 220,
                            justifyContent: 'center',
                        }}
                    >
                        Compartir
                        <i
                            className="pi pi-share-alt text-xl pl-2"
                        />
                    </Button>
                </WhatsappShareButton>
            </div>
            <ConfirmPopup className='leave-match-confirm-popup' />
        </div>
    );
};

export default MatchPlayersInfo;