import { combineReducers, configureStore } from '@reduxjs/toolkit';
import usersReducer from './usersSlice';
import registrationReducer from './registrationSlice';
import matchesReducer from './matchesSlice'
import notificationReducer from './notificationSlice'
import configReducer from './configSlice'
import rankingSlice from './rankingSlice';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';


const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({
  users: usersReducer,
  registration: registrationReducer,
  matches: matchesReducer,
  notification: notificationReducer,
  configuration: configReducer,
  ranking: rankingSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }) // Correctly include thunk and tokenMiddleware
});

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
