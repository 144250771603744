import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL;


const getToken = () => {
  const token = localStorage.getItem('token')
  return token
}

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});

apiClient.interceptors.request.use(
  async (config) => {
    const token = getToken()
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export default apiClient;
