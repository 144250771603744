import { forwardRef, useImperativeHandle } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setFormData } from '../store/registrationSlice';
import FormInput from './FormInput';
import './styles';
import { CourtPosition, Experience, SkilledHand } from '../api/apiTypes';

const LevelQuestionsForm = forwardRef<any, { onSubmit: (data: any) => void }>((props, ref) => {
  const dispatch = useDispatch<AppDispatch>();
  const formData = useSelector((state: RootState) => state.registration);

  const methods = useForm({ defaultValues: formData });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = methods;

  useImperativeHandle(ref, () => ({
    submit: () => handleSubmit(props.onSubmit)(),
    clear: () => reset(),
  }));

  const handleChange = () => {
    dispatch(setFormData(methods.getValues()));
  };

  return (
    <FormProvider {...methods}>
      <form onChange={handleChange} className="p-fluid">
        <FormInput
          name="padelExperience"
          hasFloatLabel={false}
          control={control}
          label="Tiempo que llevas jugando"
          placeholder="Tiempo que llevas jugando"
          type="dropdown"
          options={Experience}
          rules={{ required: 'La experiencia es obligatoria.' }}
          errors={errors}
        />
        <FormInput
          name="skilledHand"
          hasFloatLabel={false}
          control={control}
          label="Mano hábil"
          placeholder="Mano hábil"
          type="dropdown"
          options={[
            { label: 'Derecha', value: SkilledHand.Right },
            { label: 'Zurda', value: SkilledHand.Left },
          ]}
          rules={{ required: 'La mano hábil es obligatoria.' }}
          errors={errors}
        />
        <FormInput
          name="courtPosition"
          hasFloatLabel={false}
          control={control}
          label="Posición preferida"
          placeholder="Posición preferida"
          type="dropdown"
          options={[
            { label: 'Drive', value: CourtPosition.Drive },
            { label: 'Prefiero Drive', value: CourtPosition.PreferablyDrive },
            { label: 'Revés', value: CourtPosition.Backwards },
            { label: 'Prefiero Revés', value: CourtPosition.PreferablyBackwards },
            { label: 'No tengo preferencia', value: CourtPosition.NoPreference },
          ]}
          rules={{ required: 'La posición es obligatoria.' }}
          errors={errors}
        />
      </form>
    </FormProvider>
  );
});

export default LevelQuestionsForm;
