import React, { useEffect, useRef } from 'react';
import { Stepper } from 'primereact/stepper';
import { StepperPanel } from 'primereact/stepperpanel';
import { Button } from 'primereact/button';
import './styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { register, resetFormData } from '../store/registrationSlice';
import { LevelQuestionsForm, RegistrationForm } from '../components';
import { useNavigate } from 'react-router-dom';
import { setRegisterModalVisibility } from '../store/notificationSlice';

const SignUp: React.FC = () => {
  const stepperRef = useRef<any>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const registrationFormRef = useRef<any>(null);
  const levelQuestionsFormRef = useRef<any>(null);

  const handleRegistrationSubmit = (data: any) => {
    stepperRef.current?.nextCallback();
  };

  const onUnMount = () => {
    handleClearForms();
    dispatch(resetFormData());
  };

  useEffect(() => {
    return onUnMount();
  }, []);

  const onSuccessRegister = () => {
    handleClearForms();
    navigate('/');
    dispatch(setRegisterModalVisibility(true))
  };

  const handleLevelQuestionsSubmit = async (data: any) => {
    await dispatch(register(data, onSuccessRegister));
  };

  const handleNext = async () => {
    registrationFormRef.current?.submit();
  };

  const handleFinalSubmit = async () => {
    levelQuestionsFormRef.current?.submit();
  };

  const handleClearForms = () => {
    registrationFormRef.current?.clear();
    levelQuestionsFormRef.current?.clear();
  };

  return (
    <div className="flex align-items-center justify-content-center">
      <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
        <Stepper ref={stepperRef}>
          <StepperPanel header="Personales">
            <div className="steps-content">
              <RegistrationForm ref={registrationFormRef} onSubmit={handleRegistrationSubmit} />
            </div>
            <div className="steps-action">
              <Button label="Siguiente" className='primary-button' icon="pi pi-arrow-right" iconPos="right" onClick={handleNext} />
            </div>
          </StepperPanel>
          <StepperPanel header="Juego">
            <div className="steps-content">
              <LevelQuestionsForm ref={levelQuestionsFormRef} onSubmit={handleLevelQuestionsSubmit} />
            </div>
            <div className="steps-action">
              <Button label="Atrás" severity="secondary" className='primary-button' icon="pi pi-arrow-left" onClick={() => stepperRef.current?.prevCallback()} />
              <Button label="Enviar" icon="pi pi-check" className='primary-button' onClick={handleFinalSubmit} />
            </div>
          </StepperPanel>
        </Stepper>
      </div>
    </div>
  );
};

export default SignUp;
