import React from 'react';
import { blue, green } from '../constants/colors';

export default function Regulation() {

    const styles = {
        blueTitle: { color: blue, fontFamily: 'TTRunsBold' },
        greenTitle: { color: green, fontFamily: 'TTRunsBold' }
    }

    return (
        <div className='sm:p-4'>
            <div className="surface-card p-4 shadow-2 border-round">
                <h1 className="text-xl sm:text-3xl font-bold mb-4 text-center" style={styles.blueTitle}>REGLAMENTO PÁDEL MATCH LEAGUE</h1>
                <h2 className="text-xl sm:text-3xl font-bold mb-3 underline" style={styles.greenTitle}>LIGA PML</h2>

                <ol className="list-none p-0 m-0">
                    <li className="mb-3">
                        <span className="font-bold">1. FORMATO:</span> La liga se juega de forma individual, las parejas cambian en cada partido.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">2. TEMPORADAS:</span> La liga se compone de 4 temporadas trimestrales por año.
                        <ul className="list-none pl-3 mt-2">
                            <li><span className="font-medium">Comienzo de temporada:</span> Cada temporada comienza el primer día de cada trimestre (1 de enero, 1 de abril, 1 de julio y 1 de octubre).</li>
                            <li><span className="font-medium">Fin de temporada:</span> Cada temporada se cierra el último día de cada trimestre (31 de marzo, 30 de junio, 30 de septiembre y 31 de diciembre).</li>
                        </ul>
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">3. INSCRIPCIÓN PML:</span> El jugador deberá registrarse en nuestra aplicación web.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">4. INSCRIPCIÓN A CADA TEMPORADA:</span> Durante el 2024 la inscripción a las temporadas de la liga no tendrá costo.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">5. CATEGORÍAS:</span> Durante el 2024, PML estará formado por 8 categorías (4 masculinas y 4 femeninas). Una vez realizada la inscripción, se le asignará a cada jugador una categoría específica después de comprobar su nivel de juego a través de un formulario, y si es necesario, a través de la comprobación de uno de nuestros profesores habilitados.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">6. GANADOR DE LA TEMPORADA:</span> Cada categoría tendrá un ganador teniendo en cuenta el % de victorias (con un mínimo de [6] partidos jugados). En caso de empate, se considera el % de set ganados y luego el número de partidos jugados.
                        <ul className="list-none pl-3 mt-2">
                            <li>La puntuación empieza de cero en cada temporada.</li>
                            <li>Los premios se determinarán según el número de inscritos.</li>
                        </ul>
                    </li>
                </ol>

                <h3 className="text-xl font-bold mt-4 mb-3 underline" style={styles.greenTitle}>CANCHAS</h3>
                <ol className="list-none p-0 m-0" start={7}>
                    <li className="mb-3">
                        <span className="font-bold">7. RESERVA DE CANCHAS:</span> Cualquier jugador puede reservar y ofrecer una cancha a través del botón "Añadir partido".
                        <p>El jugador que añade el partido en la aplicación web de PML es responsable de realizar la reserva de la cancha, esto debe hacerse por fuera de nuestra aplicación web, seleccionar un club, hora y cancha no significa que la cancha esté reservada.</p>
                        <p>El costo de la cancha debe ser asumido por los cuatro jugadores.</p>
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">8. POLÍTICA DE RESERVAS:</span> Una vez que el partido esté completo, si un jugador no puede asistir será responsable de conseguir un sustituto y comunicárselo a la administración de PML hasta 24 hs antes del comienzo del partido. El sustituto debe ser parte de PML, estar en la categoría y disputando la temporada correspondiente al partido. En caso de que PML no confirme el cambio hasta 4 hs antes del comienzo del partido, o que no se consiga sustituto, el jugador que no pueda asistir debe hacerse cargo del costo completo de la cancha.
                    </li>
                </ol>

                <h3 className="text-xl font-bold mt-4 mb-3 underline" style={styles.greenTitle}>PARTIDOS</h3>
                <ol className="list-none p-0 m-0" start={9}>
                    <li className="mb-3">
                        <span className="font-bold">9. FORMACIÓN DE PARTIDOS:</span> Los jugadores tienen que añadir un partido o unirse a uno existente en nuestra aplicación web. Los primeros 4 jugadores en sumarse al partido lo disputarán.
                        <p>El jugador que añade el partido en la aplicación web de PML es responsable de realizar la reserva de la cancha, esto debe hacerse por fuera de nuestra aplicación web, seleccionar un club, hora y cancha no significa que la cancha esté reservada.</p>
                        <p>El costo de la cancha debe ser asumido por los cuatro jugadores.</p>
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">10. FORMACIÓN DE PAREJAS:</span> La aplicación web forma automáticamente las parejas cuando se conocen los 4 jugadores que disputarán el partido. Para hacer los partidos lo más parejos posibles, el jugador con mayor % de partidos ganados se emparejará con el de menor % de partidos ganados.
                        <p>Las parejas se dan a conocer 24 horas antes del comienzo del partido, una vez que el partido esté completo, en la ficha del partido en la aplicación web. Las parejas pueden verse modificadas si alguno de los jugadores disputa un partido entre que se dan a conocer las parejas y se juega el partido en cuestión.</p>
                        <p>Al momento de disputar el partido se deben respetar las parejas indicadas por la aplicación web.</p>
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">11. FORMATO DE PARTIDOS:</span> Se juegan al mejor de 3 sets, con tie-break a 7 en caso de 5-5, y se utiliza punto de oro en 40-40 en todos los sets.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">12. PELOTAS DEL PARTIDO:</span> Los 4 jugadores deben de aceptar las pelotas a utilizar. En caso de necesitar comprar pelotas nuevas todos los jugadores se deben hacer cargo del costo de las mismas.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">13. DOBLE FALTA X2:</span> Quien comete dos doble falta durante el partido deberá pagar una ronda de cerveza al resto de los jugadores. Si este jugador decide irse antes, deberá dejar su pago hecho. Si ninguno de los otros jugadores quiere tomarse una buena cerveza fría después del partido, el jugador queda exonerado y el resto deberá invitarle una cerveza.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">14. DOBLE FALTA PARA LAS CATEGORÍAS AVANZADAS:</span> Quien comete una doble falta durante el partido deberá pagar una ronda de cerveza al resto de los jugadores. Si este jugador decide irse antes, deberá dejar su pago hecho. Si ninguno de los otros jugadores quiere tomarse una buena cerveza fría, el jugador queda exonerado y el resto deberá invitarle una cerveza.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">15. LLEGADA TARDE:</span> Hay un margen de 5 minutos para llegar tarde. Si un jugador llega más tarde, deberá pagar una ronda de cerveza para los otros tres jugadores. Si el retraso es mayor a 20 minutos, deberá pagar el total de la cancha.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">16. MAL DÍA DE JUEGO:</span> Si una pareja pierde 6-0, debe deslizarse por debajo de la red tocando el suelo con la espalda obligatoriamente al finalizar el set. Si pierde por un doble 6-0, debe rodar de un lado a otro de la cancha (MILANESA).
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">17. DISPUTA DEL TERCER SET:</span> Si al comenzar el tercer set los jugadores ven que no va a dar el tiempo, faltan 20, 15 o 10 minutos para finalizar la reserva de la cancha, pueden reducir la duración de este comenzando en 2-2, 3-3 o 4-4, respectivamente.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">18. PARTIDOS NO FINALIZADOS POR FALTA DE TIEMPO:</span> En caso de no haber podido completar el partido, se debe responder "No" a la pregunta: "¿Se terminó el partido correctamente?", en la pantalla de carga de resultados en la aplicación web. Si no se completa el partido el mismo se considerará como no disputado.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">19. RETIRADA DE PARTIDOS:</span> La retirada de un partido por cualquier motivo (lesión, indisposición u otro) es considerada como derrota.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">20. RESULTADOS DE PARTIDOS:</span> Los jugadores deben cargar el resultado del partido en la aplicación web siempre antes de disputar el siguiente partido. Es responsabilidad de cada jugador hacerlo. El plazo límite para cargar el resultado es de 48 hs, si no se ha cargado el resultado en el plazo definido, la aplicación web considerará el partido como no disputado y esto no podrá ser modificado. Los errores se deben comunicar a la administración a través de uno de nuestros medio de contacto dentro del plazo de 48 hs.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">21. FALTAS SIN AVISO:</span> Si un jugador no se presenta más de una vez sin notificarlo, será expulsado de PML.
                    </li>
                </ol>

                <h3 className="text-xl font-bold mt-4 mb-3 underline" style={styles.greenTitle}>CATEGORÍAS</h3>
                <ol className="list-none p-0 m-0" start={22}>
                    <li className="mb-3">
                        <span className="font-bold">22. CAMBIO DE CATEGORÍA:</span> Luego de disputados 3 partidos dentro de una categoría, PML se reserva el derecho de realizar modificaciones si el usuario tiene un nivel no acorde a la categoría asignada.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">23. REORGANIZACIÓN DE CATEGORÍAS:</span> Al finalizar cada temporada, se puede reorganizar las categorías según el número de inscripciones para mantener la competitividad.
                    </li>
                </ol>

                <h3 className="text-xl font-bold mt-4 mb-3 underline" style={styles.greenTitle}>OTRAS CUESTIONES</h3>
                <ol className="list-none p-0 m-0" start={24}>
                    <li className="mb-3">
                        <span className="font-bold">24. PROHIBIDO HACER SPAM:</span> No se pueden enviar mensajes no relacionados con PML en el grupo de WhatsApp. Para cualquier anuncio, se debe consultar a la administración.
                    </li>
                    <li className="mb-3">
                        <span className="font-bold">25. DERECHO A EXCLUSIÓN:</span> Padel Match League se reserva el derecho para excluir jugadores y modificar normas del reglamento.
                    </li>
                </ol>

                <p className="text-center font-bold mt-4" style={{ color: blue }}>¡Bienvenido al siguiente nivel de padel en Uruguay, somos Padel Match League!</p>
            </div>
        </div>
    );
}
