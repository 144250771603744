import React, { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import FormInput from './FormInput';
import './styles';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { requestPasswordResetAction } from '../store/usersSlice';

type ForgotPasswordModalProps = {
    visible: boolean;
    onHide: () => void;
};

interface ForgotPasswordFormData {
    email: string;
}

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({ visible, onHide }) => {
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<ForgotPasswordFormData>();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch<AppDispatch>();

    const onSubmit = async (data: ForgotPasswordFormData) => {
        setLoading(true);
        await dispatch(requestPasswordResetAction(data.email));
        setLoading(false);
        onHide();
    };

    return (
        <Dialog visible={visible} onHide={onHide} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
            <div className="flex align-items-center justify-content-center">
                <div className="w-full lg:w-6">
                    <div className="text-center mb-5">
                        <div className="text-900 text-xl font-medium mb-3" style={{ fontFamily: 'TTRunsBold' }}>¿Olvidaste tu contraseña?</div>
                        <span className="text-600 font-medium line-height-3" style={{ fontFamily: 'GlacialRegular' }}>Ingresa tu email y te enviaremos instrucciones para restablecerla.</span>
                    </div>

                    <div>
                        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
                            <FormInput
                                name="email"
                                control={control}
                                label="Email"
                                type="text"
                                defaultValue=""
                                rules={{
                                    required: 'El email es obligatorio.',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Dirección de email inválida"
                                    }
                                }}
                                errors={errors}
                                hasFloatLabel={false}
                                className="w-full mb-3"
                            />

                            <Button type="submit" label="Enviar instrucciones" icon="pi pi-envelope" className="w-full primary-button" loading={loading} />
                        </form>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ForgotPasswordModal;