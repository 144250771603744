export interface Club {
  createdDate: string;
  modifiedDate: string;
  id: number;
  name?: string;
  address?: string;
  department?: string;
  phoneNumber?: string;
  isDisabled: boolean;
}

export interface ClubResponseDto {
  id: number;
  name?: string;
}

export interface Division {
  createdDate: string;
  modifiedDate: string;
  id: number;
  name?: string;
  userDivisions?: UserDivision[];
  matches?: Match[];
}

export interface DivisionDto {
  name?: string;
}

export interface DivisionResponseDto {
  id: number;
  name?: string;
  isTeamMode: boolean
}

export interface DivisionRankingDto {
  divisionName?: string;
  playerPosition: number;
}

export interface FeedDto {
  userId: string;
  rankingPositionByDivision?: DivisionRankingDto[];
  nextMatches?: MatchResponseDto[];
  lastFinishedMatches?: MatchResponseDto[];
  matchesFinishedWithoutResult?: MatchResponseDto[];
}

export interface Match {
  createdDate: string;
  modifiedDate: string;
  id: string;
  isCompleted: boolean;
  date: string;
  state: MatchState;
  creatorId: string;
  seasonId: number;
  clubId: number;
  divisionId: number;
  creator: User;
  division: Division;
  season: Season;
  club: Club;
  courtName?: string;
  courtPrice?: string;
  players?: MatchPlayer[];
  price: number;
  availableSpaces: number;
}

export interface MatchDto {
  date: string;
  creatorId: string;
  seasonId: number;
  clubId: number;
  divisionId: number;
  courtName?: string;
  courtPrice?: string;
}

export interface MatchPlayer {
  matchId: string;
  userId: string;
  match: Match;
  user: User;
}

export interface MatchResponseDto {
  creatorNickName?: string;
  date: string;
  clubName?: string;
  clubAddress?: string;
  spotsAvailable: number;
  matchState?: string;
  players?: UserIdNameDto[];
  player1: UserIdNameDto;
  player2: UserIdNameDto;
  player3: UserIdNameDto;
  player4: UserIdNameDto;
  courtName?: string;
  courtPrice?: string;
  id: string;
  hasValidResult?: boolean;
  set1WinnerSide?: number;
  set2WinnerSide?: number;
  set3WinnerSide?: number;
  set1LoserSide?: number;
  set2LoserSide?: number;
  set3LoserSide?: number;
  divisionId: number;
  userIsJoined: boolean;
  pendingToLoadResult: boolean;
  creatorId?: string;
}

export interface Season {
  id: number;
  name?: string;
  matches?: Match[];
}

export interface SeasonDto {
  name?: string;
  isEnabled: boolean;
}

export interface SeasonResponseDto {
  id: number;
  name?: string;
  isEnabled: boolean;
}

export interface User {
  createdDate: string;
  modifiedDate: string;
  id: string;
  name?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  phoneNumber?: string;
  birthDate: string;
  birthCountry?: string;
  profilePictureId?: string;
  skilledHand: SkilledHand;
  courtPosition: CourtPosition;
  hasPaid: boolean;
  isDeleted: boolean;
  userDivisions?: UserDivision[];
  matchesPlayed?: MatchPlayer[];
  createdMatches?: Match[];
}

export interface UserDivision {
  createdDate: string;
  modifiedDate: string;
  userId: string;
  divisionId: number;
  user: User;
  division: Division;
}

export interface UserDivisionDto {
  userId: string;
  divisionId: number;
}

export interface UserSeasonDto {
  userId: string;
  seasonId: number;
}

export interface UserDto {
  name?: string;
  lastName?: string;
  nickname?: string;
  email?: string;
  phoneNumber?: string;
  birthDate: string;
  birthCountry?: string;
  profilePictureId?: string;
  skilledHand: SkilledHand;
  courtPosition: CourtPosition;
  hasPaid: boolean;
  isDeleted: boolean;
  divisions?: number[];
}

export interface UserResponseDto {
  id: string;
  name: string;
  lastName: string;
  nickname: string;
  email: string;
  phoneNumber: string;
  birthDate: string;
  birthCountry: string;
  skilledHand: SkilledHand;
  courtPosition: CourtPosition;
  gender: Gender;
  department: CourtPosition;
}

export interface BasicInformationDto {
  seasons?: SeasonResponseDto[];
  divisions?: DivisionResponseDto[];
  clubs?: ClubResponseDto[];
}

export interface CancelMatchDto {
  matchId: string;
  userId: string;
}

export interface JoinMatchDto {
  matchId: string;
  userId: string;
  matchPosition?: CourtPosition.Drive | CourtPosition.Backwards,
  teamSide?: 0 | 1
}

export interface LoadMatchResultDto {
  matchId: string;
  loaderUserId: string;
  hasValidResult: boolean;
  winnerUser1?: string;
  winnerUser2?: string;
  loserUser1?: string;
  loserUser2?: string;
  set1WinnerSide?: number;
  set2WinnerSide?: number;
  set3WinnerSide?: number;
  set1LoserSide?: number;
  set2LoserSide?: number;
  set3LoserSide?: number;
}

export interface UserIdNameDto {
  nickname?: string;
  userId: string;
  isWinner?: boolean;
  matchesWonPercentage?: number;
}

export interface UserLoginDto {
  email?: string;
  password?: string;
}

export interface UserRegistrationDto {
  email?: string;
  password?: string;
  name?: string;
  lastName?: string;
  nickname?: string;
  phoneNumber?: string;
  birthDate: string;
  birthCountry?: string;
  skilledHand: SkilledHand;
  courtPosition: CourtPosition;
  profilePicture: File;
}

export type MatchState = 1 | 2 | 3 | 4;

export const Divisions = [
  { label: 'Primera Masculina', value: 1 },
  { label: 'Segunda Masculina', value: 2 },
  { label: 'Tercera Masculina', value: 3 },
  { label: 'Cuarta Masculina', value: 4 },
  { label: 'Quinta Masculina', value: 5 },
  { label: 'Primera Femenina', value: 6 },
  { label: 'Segunda Femenina', value: 7 },
  { label: 'Tercera Femenina', value: 8 },
  { label: 'Cuarta Femenina', value: 9 },
  { label: 'Quinta Femenina', value: 10 }
];

export enum SkilledHand {
  Right = 1,
  Left = 2
}

export enum CourtPosition {
  Drive = 1,
  Backwards = 2,
  PreferablyDrive = 3,
  PreferablyBackwards = 4,
  NoPreference = 5,
}

export enum PadelExperience {
  LessThanAMonth = 1,
  OneToSixMonths,
  SixMonthsToOneYear,
  OneToTwoYears,
  TwoToFourYears,
  MoreThanFourYears
}

export enum Gender {
  Male = 1,
  Female
}

export const GenderLabels = [
  { label: 'Hombre', value: Gender.Male },
  { label: 'Mujer', value: Gender.Female },
]

export const Experience = [
  { label: 'Menos de un mes', value: PadelExperience.LessThanAMonth },
  { label: '1 a 6 meses', value: PadelExperience.OneToSixMonths },
  { label: '6 meses a 1 año', value: PadelExperience.SixMonthsToOneYear },
  { label: '1 a 2 años', value: PadelExperience.OneToTwoYears },
  { label: '2 a 4 años', value: PadelExperience.TwoToFourYears },
  { label: 'Mas de 4 años', value: PadelExperience.MoreThanFourYears },
]

export const Departments = [
  { label: 'Artigas', value: 'Artigas' },
  { label: 'Canelones', value: 'Canelones' },
  { label: 'Cerro Largo', value: 'Cerro Largo' },
  { label: 'Colonia', value: 'Colonia' },
  { label: 'Durazno', value: 'Durazno' },
  { label: 'Flores', value: 'Flores' },
  { label: 'Florida', value: 'Florida' },
  { label: 'Lavalleja', value: 'Lavalleja' },
  { label: 'Maldonado', value: 'Maldonado' },
  { label: 'Montevideo', value: 'Montevideo' },
  { label: 'Paysandú', value: 'Paysandú' },
  { label: 'Río Negro', value: 'Río Negro' },
  { label: 'Rivera', value: 'Rivera' },
  { label: 'Rocha', value: 'Rocha' },
  { label: 'Salto', value: 'Salto' },
  { label: 'San José', value: 'San José' },
  { label: 'Soriano', value: 'Soriano' },
  { label: 'Tacuarembó', value: 'Tacuarembó' },
  { label: 'Treinta y Tres', value: 'Treinta y Tres' }
];

export interface Ranking {
  minimumMatches: number;
  players: RankingPlayer[]
}

export interface RankingPlayer {
  playerId: string;
  nickname: string;
  seasonId: number;
  divisionId: number;
  matchesWonPercentage: number;
  matchesWon: number;
  matchesPlayed: number;
  setsWon: number;
  setsWonPercentage: number;
  setsPlayed: number;
  hasMinimumMatches: boolean;
}

export interface UserSeasonDivisionDto {
  id: string;
  nickname?: string | null;
  isActive: boolean;
  divisions?: DivisionResponseDto[] | null;
  seasons?: SeasonResponseDto[] | null;
}