import React from 'react';
import { Link } from 'react-router-dom';
import './styles/HorizontalMenu.css';

interface MenuItem {
  label: string;
  icon: string;
  to?: string;
  visible: boolean;
  action?: () => void;
}

interface HorizontalMenuProps {
  items: MenuItem[];
  logo: React.ReactNode;
  userMenu: React.ReactNode;
  isAuthenticated: boolean;
  isMobile: boolean;
}

const HorizontalMenu: React.FC<HorizontalMenuProps> = ({ items, logo, userMenu, isAuthenticated, isMobile }) => {
  return (
    <nav className="horizontal-menu">
      <div className="menu-header">
        <div className="menu-logo">{logo}</div>
        <div className="user-menu">{userMenu}</div>
      </div>
      {(!isAuthenticated || !isMobile) && (
        <ul className="menu-items">
          {items.filter(item => item.visible).map((item, index) => (
            <li key={index}>
              {item.to ? (
                <Link to={item.to} className="menu-link">
                  <i className={`${item.icon} menu-icon`}></i>
                  <span className="menu-label">{item.label}</span>
                </Link>
              ) : (
                <button onClick={item.action} className="menu-link">
                  <i className={`${item.icon} menu-icon`}></i>
                  <span className="menu-label">{item.label}</span>
                </button>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default HorizontalMenu;