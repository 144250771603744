import React from 'react';
import { Badge } from 'primereact/badge';
import { MatchResponseDto } from '../api/apiTypes';
import './styles/MatchFinishedCard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { divisionName } from '../store/configSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { blue } from '../constants/colors';

type MatchCardProps = {
  match: MatchResponseDto;
  isLoading?: boolean;
};

const MatchFinishedCard: React.FC<MatchCardProps> = ({ match, isLoading }) => {
  const navigate = useNavigate();
  const actualRoute = useLocation();
  const divisionLabel = useSelector((state: RootState) => divisionName(match.divisionId)(state));

  const handleCardClick = () => {
    if (match.pendingToLoadResult) {
      navigate(`/match-load-result/${match.id}`);
    } else {
      navigate(`/match-info/${match.id}`);
    }
  };

  const winners = match.players?.filter(player => player.isWinner);
  const losers = match.players?.filter(player => !player.isWinner);

  const playersData = [
    {
      players: winners?.map(player => ({
        nickname: player.nickname,
        avatar: process.env.REACT_APP_STORAGE_URL + player.userId.toLowerCase(), // Replace with actual avatar URLs
        id: player.userId
      })),
      set1: match.set1WinnerSide,
      set2: match.set2WinnerSide,
      set3: match.set3WinnerSide,
      isWinner: true,
    },
    {
      players: losers?.map(player => ({
        nickname: player.nickname,
        avatar: process.env.REACT_APP_STORAGE_URL + player.userId.toLowerCase(),
        id: player.userId
      })),
      set1: match.set1LoserSide,
      set2: match.set2LoserSide,
      set3: match.set3LoserSide,
      isWinner: false,
    },
  ];

  const getGamesStyle = (teamScore?: number, opponentScore?: number) => {
    if (teamScore !== undefined && opponentScore !== undefined) {
      return teamScore > opponentScore ? { color: 'black', fontWeight: 'bold' } : { color: 'grey' };
    } else {
      return undefined
    }
  };

  const navigateToProfile = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, playerId: string) => {
    if (actualRoute.pathname.includes('match-info')) {
      event.stopPropagation();
      navigate(`/user-information/${playerId}`)
    }
  }

  const Results = (
    <div className="results-container">
      {playersData.map((teamData, index) => {
        const opponentData = playersData[1 - index];
        return (
          <React.Fragment key={index}>
            <div className="row grid">
              <div className="row col-5">
                {teamData?.players?.map((player, idx) => (
                  <div className="player" key={idx} style={{ margin: '2%', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={(event) => navigateToProfile(event, player.id)}>
                    <img
                      alt={player.nickname}
                      src={player.avatar}
                      style={{
                        borderRadius: '50%',
                        width: '50px',
                        height: '50px',
                        objectFit: 'cover',
                        borderWidth: '5px',
                        border: `1px solid ${blue}`,
                      }}
                    />
                    <div
                      className="font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis"
                      style={{ color: '#13499C' }}
                    >
                      {player.nickname}
                    </div>
                  </div>
                ))}
              </div>
              <div className="winner-info">
                {teamData.isWinner && (
                  <div className="winner-icon-container">
                    <i className="pi pi-crown winner-icon" />
                  </div>
                )}
              </div>
              <div className="set-results col">
                <span className="score" style={getGamesStyle(teamData.set1, opponentData.set1)}>
                  {teamData.set1}
                </span>
                <span className="score" style={getGamesStyle(teamData.set2, opponentData.set2)}>
                  {teamData.set2}
                </span>
                {teamData.set3 !== undefined ? (
                  <span className="score" style={getGamesStyle(teamData.set3, opponentData.set3)}>
                    {teamData.set3}
                  </span>
                ) : null}
              </div>
            </div>
            {index < playersData.length - 1 && <div className="custom-divider"></div>}
          </React.Fragment>
        );
      })}
    </div>
  );

  return (
    <div onClick={handleCardClick} style={{ backgroundColor: 'transparent', borderWidth: 2 }}>
      <div className="division-name">
        <Badge size={'large'} className="division-badge" value={divisionLabel?.toLocaleUpperCase()} />
      </div>
      <div className="results">
        {Results}
      </div>
    </div>
  );
};

export default MatchFinishedCard;
