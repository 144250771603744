
export const generateTimeOptions = () => {
    const times = [];
    const startHour = 6;
    const endHour = 24;

    for (let hour = startHour; hour < endHour; hour++) {
        times.push({ label: `${String(hour).padStart(2, '0')}:00`, value: `${String(hour).padStart(2, '0')}:00` });
        times.push({ label: `${String(hour).padStart(2, '0')}:30`, value: `${String(hour).padStart(2, '0')}:30` });
    }

    return times;
};