import { useState } from 'react'
import { blue } from '../constants/colors'

type FAQItem = {
    question: string
    answer: string
}

type FAQCategory = {
    name: string
    items: FAQItem[]
}

const faqData: FAQCategory[] = [
    {
        name: "Liga PML",
        items: [
            {
                question: "¿Cuál es el precio por jugar en PML?",
                answer: "Durante el 2024 la inscripción a las temporadas de la liga no tendrá costo."
            },
            {
                question: "¿Cuánto dura cada temporada de PML?",
                answer: "Cada temporada dura 3 meses. Hay 4 temporadas al año."
            },
            {
                question: "¿PML tiene ganadores?",
                answer: "Sí, en cada temporada habrá un ganador por categoría."
            },
            {
                question: "¿Cómo se determina el ganador de cada categoría?",
                answer: "El ganador será el jugador con el mayor % de partidos ganados dentro de la temporada."
            },
            {
                question: "¿Hay premios para los ganadores?",
                answer: "¡Por supuesto! Además, los ganadores tendrán la posibilidad de ascender de categoría."
            },
            {
                question: "¿Cómo puedo saber mi posición en la temporada?",
                answer: "Seleccionando la pestaña \"Posiciones\" en la barra de la aplicación web. Una vez ahí, debes seleccionar tu categoría y temporada correspondiente."
            }
        ]
    },
    {
        name: "Partidos",
        items: [
            {
                question: "¿Cómo me puedo anotar a un partido en la web?",
                answer: "Ir a la pestaña \"Próximos partidos\" en la barra de la aplicación web. Elegí el partido que quieras jugar y donde veas algún espacio libre, hace click para abrir la vista de los detalles y luego hace click en el botón \"Libre\". Luego de confirmar estrás anotado."
            },
            {
                question: "¿Cómo me puedo desanotar de un partido en la web?",
                answer: "Si el partido no se encuentra completo podes desanotarte haciendo click en la cruz roja que aparece sobre tu foto en la vista de detalles del partido."
            },
            {
                question: "¿Cuál es el formato de juego de los partidos de PML?",
                answer: "Los partidos son al mejor de 3 sets, con tie-break a 7 en caso de 6-6. Se juega con punto de oro."
            },
            {
                question: "Quiero jugar un partido por PML y no hay ningún partido disponible en la aplicación web ¿Puede organizarlo? ¿Cómo lo hago?",
                answer: "Cualquier jugador de PML puede subir su propio partido en la web. Para eso, lo primero, es reservar la cancha por tu cuenta y luego ofrecerla en la aplicación web a través del botón \"Añadir partido\" tanto en la pestaña \"Inicio\" como \"Próximos partidos\" y completar los datos del partido. A los demás jugadores de tu categoría les aparecerá el partido disponible en la pestaña \"Próximos partidos\". El jugador que añade el partido en la aplicación web de PML es responsable de realizar la reserva de la cancha, esto debe hacerse por fuera de nuestra aplicación web, seleccionar un club, hora y cancha no significa que la cancha esté reservada. El costo de la cancha debe ser asumido por los cuatro jugadores."
            },
            {
                question: "¿Puedo elegir mi pareja?",
                answer: "Se juega de forma individual. La web forma automáticamente las parejas cuando se conocen los 4 jugadores según el % de partidos ganados de la temporada."
            },
            {
                question: "¿Cómo se forman las parejas en cada partido?",
                answer: "Para hacer los partidos lo más parejos posibles, el jugador con mayor % de partidos ganados se emparejará con el de menor % de partidos ganados."
            },
            {
                question: "¿Cuándo sé cuáles son las parejas de mi partido?",
                answer: "24 hs antes del comienzo del partido las parejas serán visibles en la ficha del partido en la aplicación web, siempre y cuando el partido esté completo."
            },
            {
                question: "¿Qué pasa si no se finaliza el partido por falta de tiempo?",
                answer: "El mismo se considera como no disputado y se debe responder \"No\" a la pregunta: \"¿Se terminó el partido correctamente?\", en la pantalla de carga de resultados en la aplicación web. Si al comenzar el tercer set los jugadores ven que no va a dar el tiempo pueden reducir la duración de éste, comenzando en 2-2, 3-3 o 4-4."
            },
            {
                question: "¿Qué sucede si no puedo asistir a un partido programado?",
                answer: "En caso de que querer darte de baja de un partido, depende de si el mismo ya está completo (hay 4 jugadores anotados) o no. Si el partido no está completo, podes darte de baja. Si el partido está completo, tendrás que buscar un sustituto y comunicárselo a la administración de PML hasta 24 hs antes del comienzo del partido. El sustituto debe ser parte de PML, estar en la categoría y disputando la temporada correspondiente al partido. Si no te presentas al partido sin previo aviso, la organización tomará las medidas oportunas."
            },
            {
                question: "¿Qué pasa si tengo una incidencia con algún jugador?",
                answer: "Es importante que todas las incidencias sean reportadas a la organización para poder tomar decisiones."
            }
        ]
    },
    {
        name: "Canchas",
        items: [
            {
                question: "¿Hay que pagar la cancha de los partidos de la temporada?",
                answer: "Sí, el precio se indica en la ficha del propio partido en la web. Cuando los jugadores añaden un partido en la aplicación web deben indicar el precio del mismo en el apartado correspondiente."
            },
            {
                question: "¿Quién paga la cancha de los partidos de la liga?",
                answer: "El costo de la cancha debe ser asumido por los 4 jugadores."
            }
        ]
    },
    {
        name: "Categorías",
        items: [
            {
                question: "¿Cuántas categorías hay?",
                answer: "Durante el 2024, PML estará formado por 8 categorías (4 masculinas y 4 femeninas)."
            },
            {
                question: "Si siento que la categoría en la que estoy no es la correcta ¿qué tengo que hacer?",
                answer: "Nuestro objetivo es que todas las categorías tengan un nivel bastante homogéneo. Sin embargo, es posible que a algunos jugadores se les asigne una categoría que no es la correcta. Por eso, PML se reserva el derecho de realizar las modificaciones necesarias luego de disputar 3 partidos dentro de una categoría. Si crees que no estás en la categoría adecuada, contactate con los administradores de tu categoría de WhatsApp."
            },
            {
                question: "¿Cómo funcionan los ascensos y descensos?",
                answer: "Los ganadores de cada categoría tendrán la posibilidad de ascender de categoría en la próxima temporada. Además, si claramente tenes un nivel superior o inferior estudiaremos tu caso. Al finalizar cada temporada, se puede reorganizar las categorías según el número de inscripciones para mantener la competitividad."
            }
        ]
    }
]

const FAQItem = ({ item }: { item: FAQItem }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <div className="mb-2 px-2">
            <button
                className="flex justify-content-between align-items-center w-full py-3 px-3 text-left focus:outline-none"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
                style={{
                    background: '#F0F0F0',
                    borderRadius: '8px',
                    border: 'none',
                    color: '#333',
                    paddingInline: '4px'
                }}
            >
                <div className="text-sm font-bold px-2" style={{ fontFamily: 'GlacialBold' }}>
                    {item.question}
                </div>
                <i className={`pi ${isOpen ? 'pi-chevron-up' : 'pi-chevron-down'} font-bold`} style={{ color: '#666', fontSize: '12px' }}></i>
            </button>
            {isOpen && (
                <div className="px-3 py-2 text-m">
                    {item.answer}
                </div>
            )}
        </div>
    )
}

const FAQCategory = ({ category }: { category: FAQCategory }) => {
    const [isExpanded, setIsExpanded] = useState(false)
    
    return (
        <div className="mb-4">
            <button
                className="flex justify-content-between align-items-center w-full py-3 px-3 text-left focus:outline-none mb-2"
                onClick={() => setIsExpanded(!isExpanded)}
                aria-expanded={isExpanded}
                style={{
                    background: blue,
                    borderRadius: '8px',
                    border: 'none',
                    color: '#fff',
                    paddingInline: '12px',
                    transition: 'background-color 0.3s'
                }}
            >
                <div className="sm:text-ll text-sm font-bold" style={{ fontFamily: 'TTRunsBold' }}>
                    {category.name}
                </div>
                <i className={`pi ${isExpanded ? 'pi-chevron-up' : 'pi-chevron-down'} font-bold`} style={{ color: '#fff', fontSize: '16px' }}></i>
            </button>
            {isExpanded && (category.items.map((item, index) => (
                <FAQItem key={index} item={item} />
            )))}
        </div>
    )
}

export default function FAQ() {
    return (
        <div>
            <div className="text-2xl font-bold mb-3" style={{ color: blue, fontFamily: 'TTRunsBold' }}>Preguntas Frecuentes</div>
            <div className="grid grid-nogutter">
                {faqData.map((category, index) => (
                    <div key={index} className="col-12 md:col-6 px-2">
                        <FAQCategory category={category} />
                    </div>
                ))}
            </div>
        </div>
    )
}