import React, { useState, useEffect } from 'react';
import imgHomeMobile from '../assets/images/imgHomeMobile.png';
import imgHomeWeb from '../assets/images/imgHomeWeb.png';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        margin: 0,
      }}
    >
      <img
        src={isMobile ? imgHomeMobile : imgHomeWeb}
        alt="Descripción de la imagen"
        style={{
          width: '100%',
          height: '100%',
          cursor: 'pointer'
        }}
        onClick={() => navigate('signup')}
      />
    </div>
  );
};

export default Home;
