import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadMatchResultDto, MatchResponseDto } from '../api/apiTypes';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { fetchMatchDetails } from '../store/matchesSlice';
import './styles';
import { loadMatchResult } from '../api/matches/matchesApi';
import 'moment/locale/es';
import { Checkbox } from 'primereact/checkbox';
import { Toast } from 'primereact/toast';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { RadioButton } from 'primereact/radiobutton';
import { useParams } from 'react-router-dom';
import MatchMainInfo from '../components/MatchMainInfo';
import { determineWinners, Results } from '../helpers/matchResult';
import { blue, green } from '../constants/colors';

const MatchLoadResult: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { matchId } = useParams<{ matchId: string }>();
  const userId = useSelector((state: RootState) => state.users.userId);
  const navigate = useNavigate();
  const [match, setMatch] = useState<MatchResponseDto | null>(null);
  const [firstPairResults, setFirstPairResults] = useState<Results>({ game1: null, game2: null, game3: null });
  const [secondPairResults, setSecondPairResults] = useState<Results>({ game1: null, game2: null, game3: null });
  const [hasThreeSets, setHasThreeSets] = useState(false);
  const [hasValidResult, setHasValidResult] = useState(false);
  const [winners, setWinners] = useState<0 | 1 | null>(null)
  const toast = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getMatchDetailAsync = async () => {
    try {
      if (matchId !== undefined) {
        setIsLoading(true)
        const matchDetails = await dispatch(fetchMatchDetails(matchId, userId as string));
        if (matchDetails) setMatch(matchDetails);
      }
    } catch (error) {
      console.log('An error has occurred fetching the match')
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    getMatchDetailAsync();
  }, []);

  const handleResultChange = (pair: number, game: keyof Results, value: number | null) => {
    if (pair === 0) {
      setFirstPairResults({ ...firstPairResults, [game]: value });
    } else {
      setSecondPairResults({ ...secondPairResults, [game]: value });
    }
  };


  useEffect(() => {
    setFirstPairResults({ ...firstPairResults, game3: null })
    setSecondPairResults({ ...secondPairResults, game3: null })
  }, [hasThreeSets])


  useEffect(() => {
    const winners = determineWinners(firstPairResults, secondPairResults, hasThreeSets)
    setWinners(winners)
  }, [firstPairResults, secondPairResults, hasThreeSets])

  const handleLoadMatchResult = async () => {
    if (winners !== null) {
      const playersWinners = winners === 0 ? playersData[0].players : playersData[1].players;
      const playersLoosers = winners === 0 ? playersData[1].players : playersData[0].players;
      const winnersResults = winners === 0 ? firstPairResults : secondPairResults;
      const looserResults = winners === 0 ? secondPairResults : firstPairResults;

      const dto: LoadMatchResultDto = {
        matchId: matchId as string,
        loaderUserId: userId as string,
        hasValidResult: hasValidResult,
        winnerUser1: playersWinners[0]?.id,
        winnerUser2: playersWinners[1]?.id,
        loserUser1: playersLoosers[0]?.id,
        loserUser2: playersLoosers[1]?.id,
        set1WinnerSide: winnersResults.game1 as number,
        set2WinnerSide: winnersResults.game2 as number,
        set3WinnerSide: hasThreeSets ? winnersResults.game3 as number : undefined,
        set1LoserSide: looserResults.game1 as number,
        set2LoserSide: looserResults.game2 as number,
        set3LoserSide: hasThreeSets ? looserResults.game3 as number : undefined,
      };

      await loadMatchResult(dto);
      navigate('/available-matches');
    }
  };

  const playersData = match !== null ? [
    {
      players: [
        {
          nickname: match.player1.nickname,
          avatar: process.env.REACT_APP_STORAGE_URL + match.player1.userId.toLowerCase(),
          id: match.player1.userId
        },
        {
          nickname: match.player4.nickname,
          avatar: process.env.REACT_APP_STORAGE_URL + match.player4.userId.toLowerCase(),
          id: match.player4.userId
        }
      ]
    },
    {
      players: [
        {
          nickname: match.player2.nickname,
          avatar: process.env.REACT_APP_STORAGE_URL + match.player2.userId.toLowerCase(),
          id: match.player2.userId
        },
        {
          nickname: match.player3.nickname,
          avatar: process.env.REACT_APP_STORAGE_URL + match.player3.userId.toLowerCase(),
          id: match.player3.userId
        }
      ]
    },
  ] : [];

  const ResultInput = (
    <div className="results-container">
      {playersData.map((teamData, index) => {
        return (
          <React.Fragment key={index}>
            <div className="row grid">
              <div className="row col-5">
                {teamData?.players?.map((player, idx) => (
                  <div className="player" key={idx} style={{ margin: '2%', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                    <img
                      alt={player.nickname}
                      src={player.avatar}
                      style={{
                        borderRadius: '50%',
                        width: '40px',
                        height: '40px',
                        objectFit: 'cover',
                        borderWidth: '5px',
                        border: '1px solid #BDE83F',
                      }}
                    />
                    <div
                      className="font-medium white-space-nowrap overflow-hidden text-overflow-ellipsis"
                      style={{ color: '#13499C' }}
                    >
                      {player.nickname}
                    </div>
                  </div>
                ))}
              </div>
              <div className="winner-info">
                {winners !== null && winners === index ? (
                  <div className="winner-icon-container">
                    <i className="pi pi-crown winner-icon" />
                  </div>
                ) : null}
              </div>
              <div className="set-results col">
                <InputNumber maxLength={1} value={index === 0 ? firstPairResults.game1 : secondPairResults.game1} onValueChange={e => handleResultChange(index, 'game1', e.value as number)} className="input-small" />
                <InputNumber maxLength={1} value={index === 0 ? firstPairResults.game2 : secondPairResults.game2} onValueChange={e => handleResultChange(index, 'game2', e.value as number)} className="input-small" />
                {hasThreeSets ? (
                  <InputNumber maxLength={1} value={index === 0 ? firstPairResults.game3 : secondPairResults.game3} onValueChange={e => handleResultChange(index, 'game3', e.value as number)} className="input-small" />
                ) : null}
              </div>
            </div>
            {index < playersData.length - 1 && <div className="custom-divider"></div>}
            {index === playersData.length - 1 && <div className="flex justify-content-end mt-1">
              <label className="mr-2">Fueron a 3 sets?</label>
              <Checkbox onChange={e => setHasThreeSets(e.checked as boolean)} checked={hasThreeSets}></Checkbox>
            </div>}
          </React.Fragment>
        );
      })}
    </div>
  );

  const accept = () => {
    handleLoadMatchResult();
  };

  const confirmResult = () => {
    if (winners === null && hasValidResult) {
      toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Debe indicar un resultado correcto.', life: 3000 });
    } else {
      if (winners !== null) {
        confirmDialog({
          message: `La pareja ganadora fue ${playersData[winners].players[0]?.nickname} - ${playersData[winners].players[1]?.nickname}?`,
          header: 'Confirmación',
          icon: 'pi pi-info-circle',
          defaultFocus: 'accept',
          acceptLabel: 'Si',
          accept,
        });
      } else {
        confirmDialog({
          message: `El resultado no se termino correctamente?`,
          header: 'Confirmación',
          icon: 'pi pi-info-circle',
          defaultFocus: 'accept',
          acceptLabel: 'Si',
          accept,
        });
      }
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog />
      <div className="flex justify-content-center align-items-center flex-column" >
        <div className='match-info-container'>
          {match !== null ? <div className='my-2'><MatchMainInfo isLoading={isLoading} match={match as MatchResponseDto} /></div> : null}
          <div className="flex justify-content-center align-items-center p-3 mb-2 container" style={{ border: '3px solid #13499C', borderRadius: 12 }}>
            <strong className="mr-2">Se termino el partido correctamente?</strong>
            <div className="flex justify-content-center align-items-center gap-3 p-3">
              <div className="flex align-items-center">
                <RadioButton inputId="si" name="answer" value={true} onChange={e => setHasValidResult(true)} checked={hasValidResult} />
                <label htmlFor="si" className="ml-2">
                  SI
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton inputId="no" name="answer" value={false} onChange={e => setHasValidResult(false)} checked={!hasValidResult} />
                <label htmlFor="no" className="ml-2">
                  NO
                </label>
              </div>
            </div>
          </div>
          {hasValidResult ? (
            <>
              {ResultInput}
            </>
          ) : null}
          <div className='mt-2'>
            <div
              style={{
                backgroundColor: green,
                padding: 10,
                borderRadius: 8,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
              }}
              onClick={confirmResult}
            >
              <div
                className="text-xl font-bold"
                style={{
                  color: blue,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span style={{ marginRight: 8 }}>Enviar resultado</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchLoadResult;
