import React from 'react';
import { Controller, Control, RegisterOptions } from 'react-hook-form';
import { InputText, InputTextProps } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { InputNumber } from 'primereact/inputnumber';
import { FloatLabel } from 'primereact/floatlabel';
import { FileUpload } from 'primereact/fileupload';
import { InputMask } from 'primereact/inputmask';
import PhoneInput, { Country } from 'react-phone-number-input';
import { CountryCode } from 'libphonenumber-js';
import es from 'react-phone-number-input/locale/es.json'


type InputType = 'text' | 'password' | 'date' | 'date-time' | 'dropdown' | 'amount' | 'file' | 'mask' | 'phone';

interface FormInputProps extends InputTextProps {
  name: string;
  control: Control<any>;
  label: string;
  type?: InputType;
  valueType?: React.HTMLInputTypeAttribute;
  feedback?: boolean;
  defaultValue?: any;
  rules?: Omit<RegisterOptions<any, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'> | undefined;
  errors: any;
  options?: any[];
  placeholder?: string;
  hasFloatLabel?: boolean;
  className?: string;
  minDate?: Date;
  maskPattern?: string;
  helpText?: string;
  defaultCountry?: Country;
  countries?: CountryCode[];
  toggleMask?: boolean;
  onCountryChange?: (country: Country | undefined) => void;
}

const FormInput: React.FC<FormInputProps> = (props: FormInputProps) => {
  const { name, control, label, type = 'text', valueType, defaultValue = '', rules = {}, errors, options, placeholder, hasFloatLabel = true, className, minDate, maskPattern, helpText, defaultCountry, countries, toggleMask, onCountryChange, ...rest } = props;

  const getFormErrorMessage = (name: string) => {
    return errors[name] ? <small className="p-error">{errors[name]?.message}</small> : <div className="text-sm font-italic pt-1">{helpText}</div>;
  };

  const renderInput = (field: any) => {
    switch (type) {
      case 'password':
        return <Password
          id={name}
          invalid={errors[name]}
          {...field}
          {...rest}
          toggleMask={toggleMask}
          placeholder={placeholder}
          className={classNames({ 'p-invalid': errors[name] }, className)}
          promptLabel="Elige una contraseña"
          weakLabel="Débil"
          mediumLabel="Mediana complejidad"
          strongLabel="Contraseña compleja"
        />;
      case 'date':
        return <Calendar id={name} {...field} invalid={errors[name]} dateFormat="dd/mm/yy" placeholder={placeholder} showIcon className={classNames({ 'p-invalid': errors[name] }, className)} minDate={minDate} />;
      case 'date-time':
        return <Calendar id={name} {...field} invalid={errors[name]} showTime hourFormat="12" placeholder={placeholder} showIcon className={classNames({ 'p-invalid': errors[name] }, className)} minDate={minDate} />;
      case 'dropdown':
        return <Dropdown id={name} value={field.value} invalid={errors[name]} options={options} onChange={e => field.onChange(e.value)} placeholder={placeholder} className={classNames({ 'p-invalid': errors[name] }, className)} />;
      case 'amount':
        return (
          <InputNumber
            id={name}
            invalid={errors[name]}
            value={field.value}
            placeholder="$1.600"
            onValueChange={e => field.onChange(e.value)}
            mode="decimal"
            locale="es-UY"
            prefix="$"
            maxFractionDigits={0}
            className={classNames({ 'p-invalid': errors[name] }, className)}
          />
        );
      case 'file':
        return (
          <FileUpload
            id={name}
            mode="basic"
            accept="image/*"
            chooseLabel="Subir imagen"
            maxFileSize={10000000}
            onSelect={e => {
              field.onChange(e.files?.[0]);
            }}
          />
        );
      case 'mask':
        return <InputMask id={name} unmask className={classNames({ 'p-invalid': errors[name] }, className)} value={field.value} onChange={e => field.onChange(e.value)} mask={maskPattern} placeholder={placeholder} />;
      case 'phone':
        return (
          <PhoneInput
            id={name}
            {...field}
            value={field.value}
            international
            countryCallingCodeEditable={false}
            onChange={e => field.onChange(e)}
            placeholder={placeholder}
            countries={countries}
            defaultCountry={defaultCountry}
            country={defaultCountry}
            onCountryChange={onCountryChange}
            className={classNames('custom-phone-input', { 'p-invalid': errors[name] }, className)}
            labels={es}
          />
        );
      default:
        return <InputText id={name} invalid={errors[name]} {...field} type={valueType} placeholder={placeholder} className={classNames({ 'p-invalid': errors[name] })} {...rest} />;
    }
  };

  return (
    <div className="p-field">
      {hasFloatLabel ? (
        <FloatLabel>
          <Controller name={name} control={control} defaultValue={defaultValue} rules={rules} render={({ field }) => renderInput(field)} />
          <label htmlFor={name} className={classNames({ 'p-error': errors[name] })}>
            {label}
          </label>
        </FloatLabel>
      ) : (
        <div>
          <label htmlFor={name} className={classNames({ 'p-error': errors[name] })} style={{fontFamily:'GlacialRegular'}}>
            {label}
          </label>
          <Controller name={name} control={control} defaultValue={defaultValue} rules={rules} render={({ field }) => renderInput(field)} />
        </div>
      )}
      {getFormErrorMessage(name)}
    </div>
  );
};

export default FormInput;
