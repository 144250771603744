import React, { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { login } from '../store/usersSlice';
import { useForm } from 'react-hook-form';
import FormInput from './FormInput';
import './styles';
import { Checkbox } from 'primereact/checkbox';

type LoginModalProps = {
  visible: boolean;
  onHide: () => void;
  onForgotPasswordPress: () => void;
};

export interface LoginFormData {
  email: string;
  password: string;
}

const LoginModal: React.FC<LoginModalProps> = ({ visible, onHide, onForgotPasswordPress }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<LoginFormData>();
  const [loading, setLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  useEffect(() => {
    const savedData = localStorage.getItem('rememberMe');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setValue('email', parsedData.email);
      setValue('password', parsedData.password);
      setRememberMe(true);
    }
  }, [setValue]);

  const onSubmit = async (data: LoginFormData) => {
    setLoading(true);

    if (rememberMe) {
      localStorage.setItem('rememberMe', JSON.stringify(data));
    } else {
      localStorage.removeItem('rememberMe');
    }

    await dispatch(login(data));
    setLoading(false);
    navigate('/dashboard');
    onHide();
  };

  return (
    <Dialog visible={visible} onHide={onHide} style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}>
      <div className="flex align-items-center justify-content-center">
        <div className="w-full lg:w-6">
          <div className="text-center mb-5">
            <div className="text-900 text-2xl font-medium mb-3" style={{ fontFamily: 'TTRunsBold' }}>Hola de nuevo!</div>
            <span className="text-600 text-xl font-medium line-height-3" style={{ fontFamily: 'GlacialRegular' }}>No tienes cuenta?</span>
            <a
              className="font-medium no-underline ml-2 text-blue-500 text-xl cursor-pointer"
              style={{ fontFamily: 'GlacialRegular' }}
              onClick={() => {
                navigate('/signup');
                onHide();
              }}>
              Crea una ya!
            </a>
          </div>

          <div>
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <FormInput
                name="email"
                control={control}
                label="Email"
                type="text"
                defaultValue=""
                rules={{ required: 'El email es obligatorio.' }}
                errors={errors}
                hasFloatLabel={false}
                className="w-full mb-3"
              />

              <FormInput
                name="password"
                control={control}
                feedback={false}
                label="Contraseña"
                type="password"
                defaultValue=""
                rules={{ required: 'La contraseña es obligatoria.' }}
                errors={errors}
                hasFloatLabel={false}
                className="w-full mb-3"
                toggleMask={true}
              />

              <div className="flex align-items-center justify-content-between mb-6">
                <div className="flex align-items-center">
                  <Checkbox
                    id="rememberme"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.checked as boolean)}
                    className="mr-2"
                  />
                  <label htmlFor="rememberme">Recordarme</label>
                </div>
                <a onClick={onForgotPasswordPress} className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">
                  Olvidaste tu contraseña?
                </a>
              </div>

              <Button type="submit" label="Iniciar Sesión" icon="pi pi-user" className="w-full primary-button" loading={loading} />
            </form>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LoginModal;
