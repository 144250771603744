import React from 'react';
import { blue, green } from '../../constants/colors';
import { UserIdNameDto } from '../../api/apiTypes';
import { PlayersPairTheme } from './PlayersPairs';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Badge } from 'primereact/badge';
import { confirmPopup } from 'primereact/confirmpopup';

type PlayerProps = {
    theme: PlayersPairTheme;
    player: UserIdNameDto;
    canCancel?: boolean;
    onUserRemovesFromMatch?: () => void;
};

const Player: React.FC<PlayerProps> = ({ theme, player, canCancel, onUserRemovesFromMatch }) => {
    const playerImage = process.env.REACT_APP_STORAGE_URL + player.userId.toLowerCase();
    const primaryColor = theme === 'blue' ? green : blue;
    const navigate = useNavigate();
    const actualRoute = useLocation();

    const navigateToProfile = () => {
        if (actualRoute.pathname.includes('match-info')) {
            navigate(`/user-information/${player.userId}`)
        }
    }

    const onRemoveFromMatchClick = (event: any) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Estas seguro que quieres darte de baja del partido?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'accept',
            accept: () => onUserRemovesFromMatch?.(),
            acceptLabel: 'Si',
            dismissable: true
        });
    };

    return (
        <div className='customCol col' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', cursor: 'pointer' }} onClick={canCancel ? onRemoveFromMatchClick : navigateToProfile}>
            <img
                alt={player.nickname}
                src={playerImage}
                style={{ borderRadius: '50%', width: 40, height: 40, objectFit: 'cover', border: '1px solid', borderColor: primaryColor }}
            />
            {theme === 'blue' && canCancel ?
                <Badge
                    severity="danger"
                    value={<div className="pi pi-times font-bold" style={{ fontSize: 10 }} />}
                    style={{
                        position: 'absolute',
                        top: '-5px',
                        right: '50px',
                        width: '15px',
                        height: '15px',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minWidth: 0
                    }}
                    size={null}
                >
                </Badge> : null}
            <div className='text-m font-bold' style={{ color: primaryColor }}>{player.nickname}</div>
            <div className='text-sm font-bold' style={{ color: primaryColor }}>{`${player.matchesWonPercentage}%`}</div>
        </div>
    );
};

export default Player;
